export const calculateCheckoutCostsQuery = `
query ($postalCode: String!, $method: String, $instalments: Int) {
    calculateCheckoutCosts (criteria: {postalCode: $postalCode, method: $method, instalments: $instalments}) {
      shipmentCost shipmentDeadLine withdrawDeadLine totalWithFees totalFees instalmentsFee instalmentWithFee backorderShipment { value deadlineDays withdrawDeadline }
    }
}`;

export const getCheckoutInstalmentsQuery = `
query ($postalCode: String!) {
    getCheckoutInstalments (postalCode: $postalCode) {
      times fee total
    }
}`;
