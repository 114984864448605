import React, {FC, memo} from "react";
import {useTkMedia} from "../../../context/TkContext";
import Slider, {Settings} from "react-slick";
import {Link} from "react-router-dom";
import {TkRouteSearchBuilder} from "../../../views/TkSearchView";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import TkLazyImage from "../../particles/TkLazyImage";
import _CarouselSkeleton from "./_carouselSkeleton";
import {_useTkCarousel} from "./_useTkCarousel";
import TkSkeleton from "../../particles/TkSkeleton";


type Category = {
    name: string
    image: string
    divisionId: string
    familiesIds: string[]
    classesIds: string[]
    products: {
        _id: string
        name: string
        image: string
    }[]
}

const Card:FC<{
    category: Category
}> = ({category}) => {
    const url = new TkRouteSearchBuilder().setDivision({_id: category.divisionId})

    if (category.familiesIds?.length > 0) category.familiesIds.forEach(familyId => url.addFamily({_id: familyId}))
    if (category.classesIds?.length > 0) category.classesIds.forEach(classId => url.addClass({_id: classId}))

    return <div className='TkCarousel__card-category'>
        <Link to={url.build()} className='TkCarousel__card-category-face'>
            <TkLazyImage src={category.image} alt={category.name}/>
            <span>{category.name}</span>
        </Link>
        <div className='TkCarousel__card-category-products'>
            {category.products.map((product, idx) => {
                return <Link key={idx} to={tk_route_product_detail({_id: product._id, name: product.name})}
                             title={product.name}>
                    <TkLazyImage src={product.image} alt={product.name}/>
                </Link>
            })}
        </div>
    </div>
}


const TkCarouselCategory: FC<{
    categories: Category []
    isShowSkeleton?: boolean
}> = ({categories, isShowSkeleton}) => {
    const {isMediaXs, isMediaSm} = useTkMedia();
    const {getSettings} = _useTkCarousel()
    const settings: Settings = getSettings()

    if (isMediaXs || isMediaSm) {
        return <div className='TkCarousel__horizontal-scroll'>
            {isShowSkeleton ?
                Array.from({length: 2}).map((_, idx) => {
                    return <TkSkeleton key={idx} style={{
                        minWidth: 250,
                        height: 230,
                        backgroundImage: 'linear-gradient(-90deg, #fff 0%, #F8F8F8 50%, #fff 100%)'
                    }}/>
                })
                :
                categories.map((category, idx) => <Card key={idx} category={category}/>)
            }
        </div>
    }

    return isShowSkeleton ?
        <_CarouselSkeleton/>
        :
        <Slider {...settings}>
        {categories.map((category, idx) => {
            return <div key={idx}>
                <Card category={category}/>
            </div>
        })}
    </Slider>
}

export default memo(TkCarouselCategory)