import {ShipmentCost} from "../../models/shipment";
import {useCallback, useReducer, useState} from "react";
import {httpPostGraphQL} from "../../utils/http-utils";
import {calculateFromCartQuery, calculateFromPostalCodeQuery, calculateWithdrawQuery} from "./queries";
import TkShipmentCostReducer, {ShipmentCostReducerActionType, ShipmentCostType} from "./reducer";

export interface TkShipmentContextType {
    calculateFromPostalCode: (postalCode: string, productId: string, quantity?: number, backorderQuantity?: number, isSimulation?: boolean) => Promise<ShipmentCost | null | undefined>
    calculateFromCart: (postalCode: string) => Promise<ShipmentCost | null | undefined>
    calculateWithdraw: (postalCode: string) => Promise<ShipmentCost | null | undefined>
    shipmentCost: ShipmentCostType | null | undefined,
    shipmentValue: ShipmentCost | null | undefined,
    setShipmentValue: (value: ShipmentCost | null | undefined) => void
    setWithdraw: (value: boolean) => void
    isWithdraw: boolean
    isUpdatingCartItem: (id: string) => boolean
}

const TkShipmentContext = (): TkShipmentContextType => {
    const [state, dispatch] = useReducer(TkShipmentCostReducer, {
        isUpdatingCartShipment: false,
        isUpdatingProductShipment: false,
        isWithdraw: false,
        updatingCartItemId: null
    });
    const [cache, setCache] = useState(new Map())
    const [shipmentValue, setShipmentValue] = useState(null)

    const calculateFromPostalCode = useCallback(async (postalCode: string, productId: string, quantity?: number, backorderQuantity?: number, isSimulation?: boolean): Promise<ShipmentCost | null | undefined> => {

        dispatch({type: ShipmentCostReducerActionType.updating_product, updatingCartItemId: productId})
        try {
            const key = `${postalCode}${productId}${quantity}`;

            if (cache.has(key)) return Promise.resolve(cache.get(key));

            const {data: {data: result, errors}} = await httpPostGraphQL({
                query: calculateFromPostalCodeQuery,
                variables: {
                    postalCode, productId, quantity, backorderQuantity, isWithdraw: state.isWithdraw, isSimulation
                }
            });

            if (errors) {
                return Promise.reject(errors.map((e: any) => e.message))
            } else {
                const r = result?.calculateFromPostalCode || null

                dispatch({type: ShipmentCostReducerActionType.update_product, payload: r})

                if (r) {
                    cache.set(key, r)
                    setCache(cache)
                }

                return Promise.resolve(r)
            }

        } catch (e) {
            return Promise.reject(e);
        }
    }, [cache, setCache])

    const calculateFromCart = useCallback(async (postalCode: string): Promise<ShipmentCost | null | undefined> => {

        dispatch({type: ShipmentCostReducerActionType.updating_cart})
        try {
            const {data: {data: result, errors}} = await httpPostGraphQL({
                query: calculateFromCartQuery,
                variables: {postalCode}
            });

            if (errors) {
                return Promise.reject(errors.map((e: any) => e.message))
            } else {
                const r = result ? result.calculateFromCart : null
                dispatch({type: ShipmentCostReducerActionType.update_cart, payload: r})

                return Promise.resolve(r)
            }

        } catch (e) {
            return Promise.reject(e);
        }
    }, [dispatch])

    const calculateWithdraw = useCallback(async (postalCode: string): Promise<ShipmentCost | null | undefined> => {

        dispatch({type: ShipmentCostReducerActionType.updating_cart})

        try {
            const {data: {data: result, errors}} = await httpPostGraphQL({
                query: calculateWithdrawQuery,
                variables: {postalCode}
            });

            if (errors) {
                return Promise.reject(errors.map((e: any) => e.message))
            } else {
                const r = result ? result.calculateWithdraw : null
                dispatch({type: ShipmentCostReducerActionType.update_cart, payload: r})

                return Promise.resolve(r)
            }

        } catch (e) {
            return Promise.reject(e);
        }
    }, [dispatch])

    const setWithdraw = (value: boolean) =>
        dispatch({type: ShipmentCostReducerActionType.update_withdraw, isWithdraw: value})

    const isUpdatingCartItem = (id: string) => state.isUpdatingCartShipment && state.updatingCartItemId === id

    return {
        calculateFromPostalCode,
        calculateFromCart,
        calculateWithdraw,
        shipmentCost: state,
        shipmentValue,
        setShipmentValue,
        isWithdraw: state.isWithdraw,
        setWithdraw,
        isUpdatingCartItem,
    }
}

export default TkShipmentContext
