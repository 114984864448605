import {isString} from "./string-utils";

export const F8F8F8_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLAQMAAAACgOipAAAAA1BMVEX4+Phzrg4pAAAAD0lEQVQoz2MYBaNgFJAOAAM5AAFYsbMIAAAAAElFTkSuQmCC';
export const isHomologEnv = () => document.location.hostname === 'desenv.teky.com.br';
export const isProdEnv = () => process.env.NODE_ENV === 'production' && !isHomologEnv();
export const isDevEnv = () => process.env.NODE_ENV === 'development';
export const isIntersectionObserver = () => 'IntersectionObserver' in window;
export const isSomething = (arg?: any) => arg !== null && arg !== undefined;
export const copyTextToClipboard = (text: string) => {
    const el = document.createElement('textarea');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

// @ts-ignore
export const canShare = () => !!navigator.share;
export const smoothScroll = (target?: HTMLElement | string | number, duration: number = 1000, finishCallBack?: () => void) => {
    // @ts-ignore
    let t: HTMLElement = isString(target) ? document.querySelector(target + '') : target;

    const targetPos = t ? t.getBoundingClientRect().top : 0;
    const startPos = window.pageYOffset;
    const distance = targetPos - startPos;
    let startTime: any = null;


    function anim(currentTime: any) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPos, distance, duration);

        window.scrollTo(0, run);

        if (run < 1 && finishCallBack) finishCallBack();
        if (timeElapsed < duration) requestAnimationFrame(anim);
    }

    function ease(t: any, b: any, c: any, d: any) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }


    requestAnimationFrame(anim);
}

export const isVibrate = () => 'vibrate' in navigator;
export const vibrate = (pattern = 200) => {
    if (isVibrate()) navigator.vibrate(pattern);
}
export const isNumber = (v?: any) => typeof v === 'number';
export const isArray = (val?: any) => Array.isArray(val);

export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
