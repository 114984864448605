import React, {FC, memo} from 'react';

const TkCarouselNavigationButton:FC<{
    isPrev?: boolean
    onClick?: any
}> = ({isPrev, onClick}) => {
    return <button className={`TkCarousel__navigator ${isPrev ? 'prev' : 'next'}`} onClick={onClick}>
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            {isPrev ?
                <path
                    d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z"
                    fill="currentColor" transform-origin="center" transform="rotate(180)"/>
                :
                <path
                    d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z"
                    fill="currentColor"/>
            }
        </svg>
    </button>
}


export default memo(TkCarouselNavigationButton)
