import React, {FC} from "react";
import {Link} from "react-router-dom";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import {useTkMedia} from "../../../context/TkContext";
import Slider, {Settings} from "react-slick";
import {TkRouteSearchBuilder} from "../../../views/TkSearchView";
import {TTkBestClassesResult} from "../../../models/product";
import _CarouselSkeleton from "./_carouselSkeleton";
import {_useTkCarousel} from "./_useTkCarousel";
import TkSkeleton from "../../particles/TkSkeleton";


const Card: FC<{
    clazz: TTkBestClassesResult
}> = ({clazz}) => {
    clazz.products.length = 3
    clazz.groups.length = 3

    return <article className='TkCarousel__card-class'>
        <div className='TkCarousel__card-class-bottom'/>
        <div className='TkCarousel__card-class-title'>
            <Link to={new TkRouteSearchBuilder()
                .setDivision({_id: clazz.divisionId})
                .addClass({_id: clazz._id})
                .addFamily({_id: clazz.familyId})
                .build()}
                  className='ellipsis'
                  title={`Ver ${clazz.name}`}>{clazz.name}</Link>
        </div>
        <div style={{
            display: 'flex',
            alignItems: 'center',
        }}>
            {clazz.products.map((p, indexProduct) => {
                return <Link to={tk_route_product_detail(p)} title={p.name} key={indexProduct}
                             className='TkCarousel__card-class-link-product'>
                    <img src={p.image} alt={p.name} loading='lazy'/>
                </Link>
            })}
        </div>
        <div className='TkCarousel__card-class-groups'>
            {clazz.groups.map((group, idx) => {
                return <Link key={idx} title={`Ver ${group.name}`}
                             to={new TkRouteSearchBuilder()
                                 .setDivision({_id: group.divisionId})
                                 .addClass({_id: clazz._id})
                                 .addFamily({_id: group.familyId})
                                 .addGroup({_id: group._id})
                                 .build()
                             }>{group.name}</Link>
            })}
        </div>
    </article>
}

const TkCarouselClass: FC<{
    classes: TTkBestClassesResult[]
    isShowSkeleton?: boolean
}> = ({classes, isShowSkeleton}) => {
    const {isMediaXs, isMediaSm} = useTkMedia();
    const {getSettings} = _useTkCarousel()
    const settings: Settings = getSettings()


    if (isMediaXs || isMediaSm) {
        return <div className='TkCarousel__horizontal-scroll'>
            {isShowSkeleton ?
                Array.from({length: 2}).map((_, idx) => {
                    return <TkSkeleton key={idx} style={{
                        minWidth: 250,
                        height: 230,
                        backgroundImage: 'linear-gradient(-90deg, #fff 0%, #F8F8F8 50%, #fff 100%)'
                    }}/>
                })
                :
                classes.map((clazz, idx) => <Card key={idx} clazz={clazz}/>)
            }
        </div>
    }

    return isShowSkeleton ?
        <_CarouselSkeleton/>
    :
        <Slider {...settings}>
            {classes.map((clazz, indexClass) => {
                return <div key={indexClass}>
                    <Card clazz={clazz}/>
                </div>
            })}
        </Slider>
}

export default TkCarouselClass