const defaultResult = `postalCode {
    postalCode cityId cityName cityIbgeCode cityLatitude cityLongitude
    cityGeohash stateId stateName stateIbgeCode stateAcronym countryId
    countryName countryAcronym location locationType locationNotes neighborhood
} shipmentCost shipmentDeadLine withdrawDeadLine backorderShipment { value deadlineDays withdrawDeadline }`

export const calculateFromPostalCodeQuery = `query($postalCode: String!, $productId: String!, $quantity: Int, $backorderQuantity: Int, $isWithdraw: Boolean, $isSimulation: Boolean) {
calculateFromPostalCode(postalCode: $postalCode, productId: $productId, quantity: $quantity, backorderQuantity: $backorderQuantity, isWithdraw: $isWithdraw, isSimulation: $isSimulation) {${defaultResult}} 
}`;

export const calculateFromCartQuery = `query($postalCode: String!) {
calculateFromCart(postalCode: $postalCode) {${defaultResult}} 
}`;

export const calculateWithdrawQuery = `query($postalCode: String!) {
    calculateWithdraw(postalCode: $postalCode) {${defaultResult}} 
    }`;
    