import {useCallback, useState} from "react";
import useEventListener from "../hooks/UseEventListener";

export interface TkNetworkStatusContextType {
    isOnLine: boolean,
    isVisible: boolean
}

const TkNetworkStatusContext = (): TkNetworkStatusContextType => {
    const [isOnLine, setIsOnLine] = useState(true);
    const [isVisible, setIsVisible] = useState(false);

    const handlerOnLine = useCallback(setIsOnLine, []);
    const handlerVisible = useCallback(setIsVisible, []);

    useEventListener('online', () => handlerOnLine(true));
    useEventListener('offline', () => handlerOnLine(false));
    useEventListener('visibilitychange', () => handlerVisible(true));

    return {
        isOnLine,
        isVisible
    }
}

export default TkNetworkStatusContext;
