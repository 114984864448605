import React, {FC, memo, useCallback} from "react";
import {Checkbox, TextField} from "@material-ui/core";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import {useClassifierFilter} from "./_classifiersFilterStore";
import {useTkProduct} from "../../context/TkContext";
import {capitalize, isBlank} from "../../utils/string-utils";
import {useLocation} from "react-router-dom";
import {tkPrimaryColor} from "../../utils/css-utils";
import TkSkeleton from "../../components/particles/TkSkeleton";

export enum ClassifierType {
    MANUFACTURER,
    FAMILY,
    CLASS,
    GROUP
}

const AutocompleteClassifier:FC<{
    type: ClassifierType
    isVisible?: boolean
}> = ({type, isVisible = true}) => {
    const {search} = useLocation()
    const {
        state: {manufacturers, families, classes, groups, isLoadingClassifiers}
    } = useTkProduct();

    const classifierFilter = useClassifierFilter()

    const filterManufacturers = useCallback(() => {
        const param = new URLSearchParams(search)
        const divisionId = param.get('divisionId')
        let result = manufacturers ?? []
        if (!isBlank(divisionId)) result = result.filter(m => m.divisionIds?.includes(divisionId))

        return result
    }, [search, manufacturers])

    const filterFamilies = useCallback(() => {
        const param = new URLSearchParams(search)
        const divisionId = param.get('divisionId')
        let result = families ?? []
        if (!isBlank(divisionId)) result = result.filter(f => f.divisionIds?.includes(divisionId))
        return result
    }, [search, families])

    const filterClasses = useCallback(() => {
        const f = (families ?? []).filter(classifierFilter.isSelectedFamily)
        return (classes ?? []).filter(c => {
            return f.filter(f => f.classIds?.includes(c._id)).length > 0
        })
    }, [classifierFilter.selectedFamiliesIds, families, classes])

    const filterGroups = useCallback(() => {
        const cs = (classes ?? []).filter(classifierFilter.isSelectedClass)
        return (groups ?? []).filter(g => {
            return cs.filter(c => c.groupIds?.includes(g._id)).length > 0
        })
    }, [classifierFilter.selectedClassesIds, classes, groups])

    const options = useCallback(() => {
        let result: any[] = []
        if (type === ClassifierType.MANUFACTURER) result = filterManufacturers()
        if (type === ClassifierType.FAMILY) result = filterFamilies()
        if (type === ClassifierType.CLASS) result = filterClasses()
        if (type === ClassifierType.GROUP) result = filterGroups()

        return result
    }, [filterManufacturers, classifierFilter, filterClasses, filterFamilies])

    const value = useCallback(() => {
        if (type === ClassifierType.MANUFACTURER) return (manufacturers ?? []).filter(m => classifierFilter.selectedManufacturersIds.includes(m._id))
        if (type === ClassifierType.FAMILY) return (families ?? []).filter(f => classifierFilter.selectedFamiliesIds.includes(f._id))
        if (type === ClassifierType.CLASS) return (classes ?? []).filter(c => classifierFilter.selectedClassesIds.includes(c._id))
        if (type === ClassifierType.GROUP) return (groups ?? []).filter(g => classifierFilter.selectedGroupsIds.includes(g._id))

        return []
    }, [classifierFilter])

    const getLabel = useCallback((isPlaceholder: boolean = false) => {
        let label
        if (type === ClassifierType.MANUFACTURER) label = 'marcas'
        if (type === ClassifierType.FAMILY) label = 'familias'
        if (type === ClassifierType.CLASS) label = 'classes'
        if (type === ClassifierType.GROUP) label = 'grupos'

        if (isPlaceholder) return `Selecione ${label === 'grupos' ? 'o' : 'a' }s ${label}`

        return capitalize(label)
    }, [classifierFilter])

    if (!isVisible) return <></>

    if (isLoadingClassifiers) return <div style={{paddingLeft: 15, marginTop: 10}}>
            <TkSkeleton height={40} style={{width: '100%'}}/>
        </div>

    return <Autocomplete
        multiple
        id={`${type}Ids`}
        className='TkSearchView__filter-options'
        size="small"
        noOptionsText='Sem opções'
        limitTags={2}
        options={options()}
        value={value()}
        openOnFocus
        onChange={(_event, options: any) => {
            if (type === ClassifierType.MANUFACTURER) classifierFilter.selectManufacturer(...options)
            if (type === ClassifierType.FAMILY) classifierFilter.selectFamily(...options)
            if (type === ClassifierType.CLASS) classifierFilter.selectClass(...options)
            if (type === ClassifierType.GROUP) classifierFilter.selectGroup(...options)
        }}
        getOptionLabel={(option) => option.name}
        renderOption={(option, {selected}) => (
            <>
                <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small"/>}
                    checkedIcon={<CheckBox fontSize="small"/>}
                    style={{marginRight: 8, color: tkPrimaryColor}}
                    checked={selected}
                />
                {option.name}
            </>
        )}
        renderInput={(params) => (
            <TextField {...params} variant="outlined" label={getLabel()} placeholder={getLabel(true)}/>
        )}
        clearText="Limpar"
    />;
}

export default memo(AutocompleteClassifier)
