import React, {FC, memo} from "react";
import './style.scss';
import {ITkProductModel} from "../../../models/product";
import {getProductAvailabilityStatus} from "../../../utils/availability-utils";
import {availabilityClasses, availabilityLabels} from "../../../enums/availability-enum";

const TkAvailabilityCard:FC<{
    product: ITkProductModel
}> = ({ product }) => {
    const availabilityStatus = getProductAvailabilityStatus(product)
    const className = `TkAvailabilityCard TkAvailabilityCard__${availabilityClasses[availabilityStatus]}`

    return <span className={className}>{availabilityLabels[availabilityStatus]}</span>
}

export default memo(TkAvailabilityCard)