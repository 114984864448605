import React, {CSSProperties, memo, useEffect, useState} from "react";
import "./style.scss";
import TkSvgIcon from "../TkSvgIcon";
import {useTkMedia} from "../../../context/TkContext";
import {Slider} from "../../../models/slider";

const Dot: React.FC<{
    active: boolean;
}> = ({active}) => {
    return <span className={`TkSlider__dot ${active ? "active" : ""}`}>
      <TkSvgIcon icon="teky-symbol"/>
    </span>;
}

const Dots: React.FC<{
    activeIndex: number;
    count: number;
}> = ({activeIndex, count}) => {
    const dots = [];

    for (let i: number = 0; i < count; i++)
        dots.push(<Dot key={i} active={i === activeIndex}/>);

    return <div className="TkSlider__dots">{dots}</div>;
};

const Arrow: React.FC<{
    direction: string;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({direction, handleClick}) => {
    return <div
            className="TkSlider__Arrow"
            style={{
                [direction]: "100px",
            }}
        >
      <span
          onClick={handleClick}
          style={{
              transform: `translateX(${direction === "left" ? "-" : ""}2px)`,
          }}
      >
        <TkSvgIcon icon={`chevron-${direction}-solid`}/>
      </span>
    </div>
}

const Slide: React.FC<{
    imageUrl: string;
    sp: CSSProperties;
    text: string;
}> = ({imageUrl, sp, text}) => (
    <div className="TkSlider__slide">
        <img src={imageUrl}/>
        <p style={{color: sp.color, marginTop: sp.marginTop}}>{text}</p>
    </div>
);

const SliderContent: React.FC<{
    children: React.ReactNode;
    translate: any;
    transition: any;
    width: number;
}> = ({children, translate, transition, width}) => {
    return <div
            className="TkSlider__slider-content"
            style={{
                transform: translate,
                transition: transition,
                width: `${width}px`,
            }}
        >
        {children}
    </div>

};

const TkSlider: React.FC<{
    slides: Slider[];
    autoPlay?: boolean;
}> = ({slides, autoPlay = false}) => {
    const getWidth = () => window.innerWidth;
    const [state, setState] = useState({
        translate: 0,
        transition: 1.1,
        activeIndex: 0,
    });

    useEffect(() => {
        if (autoPlay) {
            const interval = setTimeout(nextSlide, 5000);
            return () => clearInterval(interval);
        }
    }, [autoPlay, state.activeIndex]);

    const {activeIndex, translate, transition} = state;

    const nextSlide = () => {
        if (activeIndex === slides.length - 1) {
            return setState({
                ...state,
                translate: 0,
                activeIndex: 0,
            });
        }

        setState({
            ...state,
            activeIndex: activeIndex + 1,
            translate: (activeIndex + 1) * getWidth(),
        });
    };

    const prevSlide = () => {
        if (activeIndex === 0) {
            return setState({
                ...state,
                translate: (slides.length - 1) * getWidth(),
                activeIndex: slides.length - 1,
            });
        }

        setState({
            ...state,
            activeIndex: activeIndex - 1,
            translate: (activeIndex - 1) * getWidth(),
        });
    };

    const {isMediaXs, isMediaSm} = useTkMedia();

    if (isMediaXs || isMediaSm) return <></>;

    return <div className="TkSlider">
        <SliderContent
            translate={`translateX(-${translate}px)`}
            transition={`transform ease-out ${transition}s`}
            width={getWidth() * slides.length}
        >
            {slides.map((i, idx) => (
                <Slide key={idx} imageUrl={i.img} sp={i.style} text={i.text}/>
            ))}
        </SliderContent>

        <Arrow direction="left" handleClick={prevSlide}/>
        <Arrow direction="right" handleClick={nextSlide}/>

        <Dots count={slides.length} activeIndex={activeIndex}/>
    </div>
};

export default memo(TkSlider);
