import React, {memo} from "react";
import {useTkListPro, useTkMedia, useTkUser,} from "../../../context/TkContext";
import {ITkProductModel} from "../../../models/product";
import TkSvgIcon from "../../particles/TkSvgIcon";

const TkListCombo: React.FC<{
  product: ITkProductModel;
  className?: string;
}> = ({ className, product }) => {
  const { isTypeStandard, isAuth } = useTkUser();
  const { setProduct } = useTkListPro();
  const { isMediaXs, isMediaSm } = useTkMedia();

  if (isTypeStandard() || !isAuth()) return <></>;

  return (
    <button
      type="button"
      onClick={() => {
        window.scrollTo(0, 0);
        setProduct(product);
      }}
      className={`btn ${
        isMediaXs || isMediaSm ? "" : "btn-sm"
      } btn-destak-purple btn-block ${className}`}
    >
      <TkSvgIcon icon="material-playlist-add-check" />
      <b> Adicionar à Lista PRO</b>
    </button>
  );
};

export default memo(TkListCombo);
