import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import {useTkProduct} from "../../../context/TkContext";
import {TTkBestProductsCategoriesResult} from "../../../models/product";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCarousel from "../TkCarousel";
import categories from "../../../assets/json/categories.json";
import {getS3Object} from "../../../utils/http-utils";

const TkCarouselBestCategories:FC = () => {
    const {getBestProductsCategories} = useTkProduct();
    const refRelated = useRef<HTMLSpanElement>()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [bestCategoryProducts, setBestCategoryProducts] = useState<TTkBestProductsCategoriesResult[]>([]);
    const highlightCategories = categories.map((category) => {
        return {
            ...category,
            image: getS3Object(category.image),
        }
    })

    useEffect(() => {
        let observer: any;

        if (refRelated.current && isIntersectionObserver()) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(refRelated.current)
                            setLoading(true)
                            getBestProductsCategories(highlightCategories.map((category) => category.divisionId), highlightCategories.flatMap(c => c.familiesIds), highlightCategories.flatMap(c => c.classesIds))
                                .then(setBestCategoryProducts)
                                .finally(() => setLoading(false))
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(refRelated.current);
        }

        return () => {
            if (observer?.unobserve && refRelated.current) observer.unobserve(refRelated.current);
        };
    }, [refRelated, setLoading, setBestCategoryProducts]);

    const filterCategories = useMemo(() => {
        const results:any[] = highlightCategories.map(cat => {
            return {
                ...cat,
                products: []
            }
        })
        if (bestCategoryProducts?.length > 0){
            const productsIds = new Set(results.flatMap(r => r.products.map((p: any) => p._id)));

            for (const bcp of bestCategoryProducts) {
                for (const result of results) {
                    if (result.products.length > 2) continue;
                    if (bcp.classesIds.find(id => result.classesIds.includes(id))) {
                        result.products.push(bcp);
                        productsIds.add(bcp._id);
                        break;
                    }

                    if (bcp.familiesIds.find(id => result.familiesIds.includes(id))) {
                        result.products.push(bcp);
                        productsIds.add(bcp._id);
                        break;
                    }
                }
            }

            for (const bcp of bestCategoryProducts) {
                for (const result of results) {
                    if (result.products.length > 2 || result.products.find((p: any) => p._id === bcp._id)) continue;
                    if (bcp.divisionId === result.divisionId) {
                        result.products.push(bcp);
                        productsIds.add(bcp._id);
                        break;
                    }
                }
            }
        }

        return results
    }, [bestCategoryProducts])


    return <>
        <span ref={refRelated}/>
        <TkCarousel.Root title='Categorias em Destaque'>
            <div className='TkCarousel__padding'>
                <TkCarousel.Category isShowSkeleton={isLoading} categories={filterCategories}/>
            </div>
        </TkCarousel.Root>
    </>
}

export default TkCarouselBestCategories