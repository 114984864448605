import {useEffect, useRef} from "react";

const useEventListener = (eventName: string, handler: EventListenerOrEventListenerObject, element: Window = window) => {
    const savedHandler = useRef({});

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            const isSupported = element && element.addEventListener;
            if (!isSupported || !savedHandler || !savedHandler.current) return;

            // @ts-ignore
            const eventListener = (e: EventListenerOrEventListenerObject) => savedHandler.current(e);

            // @ts-ignore
            element.addEventListener(eventName, eventListener);

            // @ts-ignore
            return () => element.removeEventListener(eventName, eventListener);
        },
        [eventName, element]
    );
};


export default useEventListener;
