import React, {memo} from "react";
import './style.scss';


const TkStepper: React.FC<{
    labels: string[],
    activeIndex?: number | null
}> = ({labels, activeIndex = 0}) => {

    return <div className='TkStepper'>
        {labels.map((l, idx) => (
            <React.Fragment key={idx}>
                <span className={`TkStepper__title Step_${idx} ${activeIndex === labels.length - 1 ? 'Step_end' : ''} ${idx === activeIndex ? 'TkStepper__title--active' : ''} ${idx <= activeIndex ? 'Stepped' : ''}`}>
                    <span
                        className={`TkStepper__counter-holder ${idx === activeIndex ? '' : 'TkStepper__counter-holder--disabled'}`}>{idx + 1}</span>
                    <span
                        className={`TkStepper__title-holder ${idx === activeIndex ? '' : 'TkStepper__title-holder--disabled'}`}>{l}</span>
                </span>
                {idx < labels.length - 1 && <div className='TkStepper__line'><span/></div>}
            </React.Fragment>
        ))}
    </div>
};

export default memo(TkStepper)
