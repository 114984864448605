import React, {FC, useCallback, useEffect} from "react"
import "./style.scss";
import {useLocation} from "react-router-dom";
import ChooseTypeAccount from './_chooseTypeAccount'
import ChooseEmailAccount from './_chooseEmailAccount'
import EmailAccount from './_emailAccount'
import SignInOrCreateAccount from './_signInOrCreateAccount'
import SuccessSignUp from './_successSignUp'
import CompleteData from './_completeData'
import DeviceStorage from "../../utils/storage-utils";

export const tk_route_sign_up = '/cadastro';
export const tk_route_sign_up_pro = `${tk_route_sign_up}?estagio=email&tipo=pro`;

const TkSignUpView:FC = () => {
    const {search} = useLocation()

    useEffect(() => DeviceStorage.destroyEmailSignup(), [])

    const printStage = useCallback(() => {
        const p = new URLSearchParams(search)

        const stg = p.get('estagio');

        if (stg === 'email') return <EmailAccount/>
        if (stg === 'converter-ou-mudar-email') return <ChooseEmailAccount/>
        if (/(criar|converter)/.test(stg)) return <SignInOrCreateAccount/>
        if (/(criacao|conversao)-sucesso/.test(stg)) return <SuccessSignUp/>
        if (stg === 'completar-dados') return <CompleteData/>

        return <ChooseTypeAccount/>
    }, [search])


    return <>

        {printStage()}
    </>

}


export default TkSignUpView