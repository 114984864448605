import React, {memo} from "react";
import "./style.scss";
import TkLazyImage from "../TkLazyImage";

const TkLazyImageSlider: React.FC<{
  images: any[];
}> = ({ images }) => {
  return (
    <div className="TkLazyImageSlider TkLazyImageSlider__carousel">
      {images.map((i, idx) => (
        <div key={idx} className="TkLazyImageSlider TkLazyImageSlider__card">
          <TkLazyImage src={i} className="img-responsive"/>
        </div>
      ))}
    </div>
  );
};

export default memo(TkLazyImageSlider);
