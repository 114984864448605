import {useState} from "react";
import {ITkProductModel} from "../models/product";

export interface TkListProContextType {
    hasProduct: boolean;
    product?: ITkProductModel;
    setProduct: (product: ITkProductModel) => void
}

const TkListProContext = (): TkListProContextType => {
    const [selectedProduct, setProduct] = useState<ITkProductModel>(null);

    return {
        hasProduct: !!selectedProduct,
        product: selectedProduct,
        setProduct
    };
};

export default TkListProContext
