import {Address, Company, UserAuth} from "../../models/user";

export interface UserReducerType {
    userAuth?: UserAuth | null
}

export enum UserReducerActionType {
    me,
    sign_in,
    sign_up,
    sign_out,
    update_personal_data,
    update_address,
    save_company,
}

export interface UserReducerAction {
    payload?: any | null,
    type: UserReducerActionType
}

const sortDefaultFirst = (a: Address | Company, b: Address | Company) => a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1

const TkUserReducer = (state: UserReducerType, action: UserReducerAction): UserReducerType => {
    let newState;
    switch (action.type) {
        case UserReducerActionType.sign_up:
        case UserReducerActionType.sign_in:
            return {userAuth: action.payload};
        case UserReducerActionType.update_address:
            newState = {
                ...state
            };
            if (newState.userAuth?.user?.addresses?.length > 0) {
                let {addresses} = newState.userAuth.user;
                addresses = [...addresses.filter(c => c._id !== action.payload._id), action.payload]
                addresses.sort(sortDefaultFirst)

                if (action.payload.isDefault) newState.userAuth.user.defaultAddress = action.payload
                else {
                    const filter = addresses.filter(c => c.isDefault)
                    if (filter.length > 0) newState.userAuth.user.defaultAddress = filter[0]
                }
            } else {
                newState.userAuth.user.addresses = [action.payload];
                newState.userAuth.user.defaultAddress = action.payload;
            }

            return newState;
        case UserReducerActionType.save_company:
            newState = {...state};

            if (newState.userAuth?.user?.companies?.length > 0) {
                let {companies} = newState.userAuth.user;
                companies = [...companies.filter(c => c.document !== action.payload.document), action.payload]
                companies.sort(sortDefaultFirst)

                if (action.payload.isDefault) newState.userAuth.user.defaultCompany = action.payload
                else {
                    const filter = companies.filter(c => c.isDefault)
                    if (filter.length > 0) newState.userAuth.user.defaultCompany = filter[0]
                }
            } else if (newState.userAuth?.user) {
                newState.userAuth.user.companies = [action.payload];
                newState.userAuth.user.defaultCompany = action.payload;
            }

            return newState;
        case UserReducerActionType.me:
            newState = {
                ...state,
                userAuth: {
                    user: action.payload
                }
            };
            return newState;
        case UserReducerActionType.update_personal_data:
            newState = {
                ...state
            };
            if (newState?.userAuth?.user) {
                const {document, phone} = action.payload;
                newState.userAuth.user.document = document;
                newState.userAuth.user.phone = phone;
            }

            return newState;
        case UserReducerActionType.sign_out:
            return {
                userAuth: {
                    user: null
                }
            };
        default:
            return state;

    }
};


export default TkUserReducer
