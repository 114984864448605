import {ShipmentCost} from "../../models/shipment";


export interface ShipmentCostType {
    productShipment?: ShipmentCost | null
    isUpdatingProductShipment: boolean
    cartShipment?: ShipmentCost | null
    isUpdatingCartShipment: boolean
    updatingCartItemId?: string
    isWithdraw: boolean
}

export enum ShipmentCostReducerActionType {
    update_product,
    update_cart,
    updating_product,
    updating_cart,
    update_withdraw
}

export interface ShipmentCostReducerAction {
    payload?: ShipmentCost | null | undefined
    type: ShipmentCostReducerActionType
    isWithdraw?: boolean
    updatingCartItemId?: string
}

const TkShipmentCostReducer = (state: ShipmentCostType, action: ShipmentCostReducerAction): ShipmentCostType => {
    let newState = {...state};
    newState.isUpdatingProductShipment = false
    newState.isUpdatingCartShipment = false
    newState.updatingCartItemId = null

    switch (action.type) {
        case ShipmentCostReducerActionType.update_product:
            newState.productShipment = action.payload
            break
        case ShipmentCostReducerActionType.update_cart:
            newState.cartShipment = action.payload
            break
        case ShipmentCostReducerActionType.updating_product:
            newState.isUpdatingProductShipment = true
            break
        case ShipmentCostReducerActionType.updating_cart:
            newState.isUpdatingCartShipment = true
            newState.updatingCartItemId = action.updatingCartItemId
            break
        case ShipmentCostReducerActionType.update_withdraw:
            newState.isWithdraw = action.isWithdraw
            break
    }

    return newState;
}

export default TkShipmentCostReducer