export const searchPostalCodeQuery = `query ($postalCode: String!) {
                        searchPostalCode(postalCode: $postalCode) {
                            postalCode cityId cityName cityIbgeCode cityLatitude cityLongitude
                            cityGeohash stateId stateName stateIbgeCode stateAcronym countryId
                            countryName countryAcronym location locationType locationNotes neighborhood
                        }
                    }`;


export const getStatesQuery = `query {
                        getStates { _id name acronym countryId ibgeCode }
                    }`;


export const getCitiesQuery = `query ($stateId: String!) {
                        getCities (stateId: $stateId) { _id name stateId ibgeCode latitude longitude }
                    }`;
