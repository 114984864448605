import {
    ITkClassModel,
    ITkDivisionModel,
    ITkFamilyModel,
    ITkGroupModel,
    ITkManufacturerModel
} from "../../models/product";


export interface ProductReducerType {
    manufacturers?: ITkManufacturerModel[]
    divisions?: ITkDivisionModel[]
    families?: ITkFamilyModel[]
    classes?: ITkClassModel[]
    groups?: ITkGroupModel[]
    isLoadingClassifiers?: boolean
}

export enum ProductReducerActionType {
    set_manufacturers = 'manufacturers',
    set_divisions= 'divisions',
    set_families= 'families',
    set_classes= 'classes',
    set_groups= 'groups',
    set_loading_classifiers= 'loading_classifiers',
}

export interface ProductReducerAction {
    payload?: any | null,
    type: ProductReducerActionType
}


const TkProductReducer = (state: ProductReducerType, action: ProductReducerAction): ProductReducerType => {
    switch (action.type) {
        case ProductReducerActionType.set_manufacturers:
            return {
                ...state,
                manufacturers: action.payload
            }
        case ProductReducerActionType.set_divisions:
            return {
                ...state,
                divisions: action.payload
            }
        case ProductReducerActionType.set_families:
            return {
                ...state,
                families: action.payload
            }
        case ProductReducerActionType.set_classes:
            return {
                ...state,
                classes: action.payload
            }
        case ProductReducerActionType.set_groups:
            return {
                ...state,
                groups: action.payload
            }
        case ProductReducerActionType.set_loading_classifiers:
            return {
                ...state,
                isLoadingClassifiers: action.payload
            }

    }


    return state
}


export default TkProductReducer
