const primaryColor = '--primary-color';


const getCssColor = (cssVariableName: string) => getComputedStyle(document.documentElement)
.getPropertyValue(cssVariableName)

const tkPrimaryColor = getCssColor(primaryColor)


export {
    tkPrimaryColor
}
