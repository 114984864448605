import React, {memo, useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import './style.scss'
import {useTkMedia} from "../../../context/TkContext";
import TkSvgIcon from "../TkSvgIcon";


const SmallPaginator: React.FC<{
    pageSize: number
    from: number
    totalItems: number
    goToUrl: (url: string) => void
}> = ({pageSize, from, totalItems, goToUrl}) => {
    const location = useLocation();
    const {isMediaXs} = useTkMedia()
    const maxPages = isMediaXs ? 3 : 5;

    const createPages = useCallback(() => {
        const pages = [];
        let start = 0, end = maxPages;
        const pageCount = Math.floor(totalItems / pageSize);
        const rest = totalItems % pageSize;
        const totalPages = pageCount + (rest > 0 ? 1 : 0);
        const currentPage = from === 0 ? 1 : from / pageSize + 1;

        if (currentPage > 2) {
            start = currentPage - 2;

            end = start + maxPages;

            if (currentPage >= totalPages - 2) {
                start = totalPages - (totalPages < maxPages ? totalPages : maxPages);
                end = totalPages;
            }
        }

        if (totalPages < maxPages) end = totalPages;

        const params = new URLSearchParams(location.search);

        for (let i = start; i < end; i++) {
            const from = (i === 0 ? 0 : i * pageSize);
            params.set('from', from + '');
            pages.push({label: i + 1, from, url: `${location.pathname}?${params.toString()}`});
        }

        return pages;
    }, [from, totalItems, location]);

    const isLastPage = useCallback(() => {
        const pageCount = Math.floor(totalItems / pageSize);
        const rest = totalItems % pageSize;
        const totalPages = pageCount + (rest > 0 ? 1 : 0);
        const currentPage = from === 0 ? 1 : from / pageSize + 1;

        return totalPages === currentPage;
    }, [from, totalItems]);

    const goTo = useCallback((isForward = false) => {
        const params = new URLSearchParams(location.search);

        if (isForward && !isLastPage()) {
            params.set('from', (from + pageSize) + '');
        } else if (from !== 0) {
            params.set('from', (from - pageSize) + '');
        }

        goToUrl(`${location.pathname}?${params.toString()}`);
    }, [from, totalItems, isLastPage, location]);

    return <>
        <button type='button' disabled={from === 0} onClick={() => goTo()} className='btn btn-ice-gray btn-sm'>
            <TkSvgIcon icon='chevron-left-solid'/> Anterior
        </button>
        <div className='TkPaginator__page-buttons'>
            {createPages().map((p, idx) => (
                <button key={idx}
                        disabled={from === p.from}
                        onClick={() => goToUrl(p.url)}
                        className='btn btn-ice-gray btn-sm'
                        title={`Ir para página ${p.label}`}>{p.label}</button>
            ))}
        </div>
        <button type='button' disabled={isLastPage()} onClick={() => goTo(true)} className='btn btn-ice-gray btn-sm'>
            Próximo <TkSvgIcon icon='chevron-right-solid'/>
        </button>
    </>
};

const TkPaginator: React.FC<{
    pageSize?: number
    totalItems: number
    goToUrl: (url: string) => void
}> = ({pageSize = 12, totalItems, goToUrl}) => {
    const location = useLocation();
    const [from, setFrom] = useState(0);


    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params.has('from')) setFrom(parseInt(params.get('from')));
        else setFrom(0);

    }, [location.search]);


    if (totalItems < pageSize) return <></>;

    return <div className='TkPaginator_container'>
        <SmallPaginator pageSize={pageSize}
                        from={from}
                        goToUrl={goToUrl}
                        totalItems={totalItems}/>
    </div>
};

export default memo(TkPaginator)
