import {City, PostalCode, State} from "../../models/localization";
import {useCallback} from "react";
import {httpPostGraphQL} from "../../utils/http-utils";
import {getCitiesQuery, getStatesQuery, searchPostalCodeQuery} from "./queries";
import DeviceStorage from "../../utils/storage-utils";

export interface TkLocalizationContextType {
    searchPostalCode: (postalCode: string) => Promise<PostalCode>,
    getStates: () => Promise<State[]>,
    getCities: (stateId: string) => Promise<City[]>
}

const STATES_STORAGE = 'STATES_STORAGE'
const postalCodeCache: Map<string, PostalCode> = new Map<string, PostalCode>()
const citiesCache: Map<string, City[]> = new Map<string, City[]>()
const TkLocalizationContext = (): TkLocalizationContextType => {

    const searchPostalCode = useCallback(async (postalCode: string): Promise<PostalCode> => {
        if (postalCodeCache.has(postalCode)) return postalCodeCache.get(postalCode)
        try {
            const {data: result} = await httpPostGraphQL({
                query: searchPostalCodeQuery,
                variables: {postalCode}
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {searchPostalCode}} = result;

                postalCodeCache.set(postalCode, searchPostalCode)

                return Promise.resolve(searchPostalCode)
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    const getStates = useCallback(async (): Promise<State[]> => {
        try {
            const storage: State[] = DeviceStorage.getObject(STATES_STORAGE)
            if (!!storage && storage.length > 0) return storage

            const {data: result} = await httpPostGraphQL({
                query: getStatesQuery
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {getStates}} = result;

                DeviceStorage.putObject(STATES_STORAGE, getStates)

                return getStates
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    const getCities = useCallback(async (stateId: string): Promise<City[]> => {
        try {
            if (citiesCache.has(stateId)) return citiesCache.get(stateId)

            const {data: result} = await httpPostGraphQL({
                query: getCitiesQuery,
                variables: {stateId}
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {getCities}} = result;

                citiesCache.set(stateId, Object.freeze(getCities))

                return Promise.resolve(getCities)
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    return {
        searchPostalCode,
        getCities,
        getStates
    }
}


export default TkLocalizationContext;
