import React, {FC, memo, useEffect, useRef, useState} from "react";
import {useTkMedia, useTkProduct} from "../../../context/TkContext";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCardProductSlider from "../TkCardProductSlider";
import TkCarousel from "../TkCarousel";
import {isBlank} from "../../../utils/string-utils";
import {ITkProductModel} from "../../../models/product";

const TkCarouselSimilarityProducts:FC<{
    externalId?: string
}> = ({externalId}) => {
    const {isMediaXs, isMediaSm} = useTkMedia()
    const ref = useRef<HTMLDivElement>()
    const {getSimilarityProducts} = useTkProduct()
    const title = 'Produtos similares'
    const [similarityProducts, setSimilarityProducts] = useState<ITkProductModel[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        let observer: any;

        if (ref.current && isIntersectionObserver() && !isBlank(externalId)) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(ref.current)
                            setLoading(true)
                            getSimilarityProducts(externalId)
                                .then(setSimilarityProducts)
                                .finally(() => setLoading(false))
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(ref.current);
        }

        return () => {
            if (observer?.unobserve) observer.unobserve(ref.current);
        };
    }, [ref, externalId]);

    if (isBlank(externalId)) return null

    return <>
        <div ref={ref}/>

        {similarityProducts?.length > 0 && <>

            <div>
                {isMediaXs || isMediaSm ?
                    <>
                        <h2 className="logo">{title}</h2>
                        <TkCardProductSlider products={similarityProducts}
                                             isLoadingProducts={isLoading}/>
                    </>
                    :
                    <TkCarousel.Root title={title}>
                        <div className='TkCarousel__padding'>
                            <TkCarousel.Product products={similarityProducts}
                                            isShowSkeleton={isLoading}/>
                        </div>
                    </TkCarousel.Root>
                }
            </div>
        </>}
    </>
}

export default memo(TkCarouselSimilarityProducts)