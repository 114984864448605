import React, {forwardRef, ForwardRefRenderFunction, useCallback, useImperativeHandle, useState} from "react";
import TkModal, {TkModalActions, TkModalBody} from "../../particles/TkModal";
import TkSvgIcon from "../../particles/TkSvgIcon";
import {Address} from "../../../models/user";
import {useTkAppErrors, useTkUser} from "../../../context/TkContext";
import TkSkeleton from "../../particles/TkSkeleton";
import {ITkModalChangeDefaultCompany} from "../TkChangeDefaultCompany";
import {Link, useLocation} from "react-router-dom";
import {tk_route_address} from "../../../views/TkMyAddressView";

export interface ITkModalChangeDefaultAddress {
    show(): void
}

const TkChangeDefaultAddress: ForwardRefRenderFunction<ITkModalChangeDefaultCompany, {
    onPickupAddress?: (address: Address) => void
}> = ({onPickupAddress}, ref) => {
    const {pathname} = useLocation()
    const [isVisible, setIsVisible] = useState(false)
    const {me, updateAddress, isTypeBusiness, isAuth} = useTkUser();
    const {goToPageError} = useTkAppErrors()
    const [loading, setLoading] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [addresses, setAddresses] = useState<Address[]>([])

    const saveAddress = useCallback(async (address: Address) => {
        try {
            setSaving(true)
            const {state, city} = address;

            await updateAddress({...address, stateId: state._id, cityId: city._id, isDefault: true});

            const newState = [...addresses]
            newState.forEach(c => c.isDefault = c._id === address._id)
            setAddresses(newState)

            if (onPickupAddress) onPickupAddress(address);
        } catch (e) {
            goToPageError(e)
        } finally {
            setSaving(false)
        }

    }, [updateAddress, onPickupAddress, addresses, setAddresses, setSaving])

    useImperativeHandle(ref, () => {
        return {
            show() {
                setIsVisible(true)
                if (isAuth()) {
                    setLoading(true)
                    me()
                        .then(user => {
                            if (isTypeBusiness()) {
                                if (user?.defaultCompany?.addresses?.length > 0)
                                    setAddresses(user?.defaultCompany?.addresses)
                            } else {
                                if (user?.addresses?.length > 0)
                                    setAddresses(user.addresses || [])
                            }
                        })
                        .catch(goToPageError)
                        .finally(() => setLoading(false))
                }
            }
        }
    }, [isAuth, isTypeBusiness, setAddresses, goToPageError, setLoading, setIsVisible])

    const urlToAdd = useCallback(() => {
        const p = new URLSearchParams()
        p.set('from', pathname)

        return `${tk_route_address(true)}?${p.toString()}`
    }, [pathname])


    return <TkModal show={isVisible} title='Selecione o Endereço' close={() => {
        if (!saving) setIsVisible(false);
        window.location.reload();
    }}>
        <TkModalBody>
            <ul className='TkCheckoutView__list-address'>
                {loading ?
                    <>
                        <li>
                            <TkSvgIcon icon='sync-solid' className='rotate-1-seg m-r-1em'/>
                            <TkSkeleton width='100%' height='1em' style={{display: 'inline-block'}}/>
                        </li>
                        <li>
                            <TkSvgIcon icon='sync-solid' className='rotate-1-seg m-r-1em'/>
                            <TkSkeleton width='100%' height='1em' style={{display: 'inline-block'}}/>
                        </li>
                    </>
                    :
                    addresses.map((a) => (
                        <li key={a._id} onClick={async () => {
                            if (!a.isDefault) await saveAddress(a)
                        }}><TkSvgIcon
                            icon={`${a.isDefault ? 'check' : 'map-marker-alt'}-solid`}/> {a.location}, {a.locationNumber} - {a.neighborhoodName}</li>
                    ))
                }
            </ul>
        </TkModalBody>

        <TkModalActions>
            <Link to={urlToAdd()}
                    className='btn btn-primary'><TkSvgIcon icon='plus-solid'/> Adicionar</Link>
            <button type='button'
                    disabled={saving}
                    className='btn btn-secondary m-t-10px'
                    onClick={() => window.location.reload()}>{saving ? 'Salvando...' : 'Fechar'}</button>
        </TkModalActions>
    </TkModal>
};

export default forwardRef(TkChangeDefaultAddress)
