import React, {FC, memo, useMemo} from "react";
import {useTkMedia} from "../../../context/TkContext";
import TkSkeleton from "../../particles/TkSkeleton";
import Slider, {Settings} from "react-slick";

const _CarouselSkeleton:FC<{
    style?: React.CSSProperties
}> = ({style}) => {
    const {isMediaXl, isMediaMd, isMediaXs, isMediaSm} = useTkMedia();
    const slides = isMediaXl ? 5 : 3;

    const length = useMemo(() => {
        if (isMediaXs || isMediaSm) return 1
        if (isMediaMd) return 3

        return 5
    }, [isMediaXs, isMediaSm, isMediaMd, isMediaXl])

    const settings: Settings = {
        infinite: true,
        dots: false,
        arrows: false,
        draggable: true,
        autoplay: false,
        pauseOnHover: true,
        speed: 300,
        slidesToShow: slides,
        slidesToScroll: slides,
        cssEase: "linear",
        variableWidth: false,
        responsive: [
            {
                breakpoint: 1462,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    arrows: false
                }
            },
            {
                breakpoint: 1144,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: false
                }
            },
            {
                breakpoint: 826,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false
                }
            }
        ]
    }


    return <Slider {...Object.assign(settings, {arrows: false})}>
        {
            Array.from({length}).map((_, idx) => {
                return <div key={idx}>
                    <TkSkeleton style={{
                        width: '100%',
                        height: '250px',
                        borderRadius: '5px',
                        ...style
                    }}/>
                </div>
            })
        }
    </Slider>
}

export default memo(_CarouselSkeleton)