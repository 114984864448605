import React from 'react'
import {Settings} from "react-slick";
import TkCarouselNavigationButton from "./_carouselNavigationButton";
import {useTkMedia} from "../../../context/TkContext";


export const _useTkCarousel = () => {
    const {isMediaXl} = useTkMedia();
    const slides = isMediaXl ? 5 : 3;

    const getSettings = (settings?: Settings) => {
        const defaultSettings: Settings = {
            infinite: true,
            dots: false,
            arrows: true,
            draggable: true,
            autoplay: false,
            pauseOnHover: true,
            speed: 300,
            slidesToShow: slides,
            slidesToScroll: slides,
            cssEase: "linear",
            variableWidth: false,
            prevArrow: <TkCarouselNavigationButton isPrev={true}/>,
            nextArrow: <TkCarouselNavigationButton/>,
            responsive: [
                {
                    breakpoint: 1462,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1144,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: true
                    }
                },
                {
                    breakpoint: 826,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false
                    }
                }
            ]
        }

        return settings ? {...defaultSettings, ...settings} : defaultSettings
    }

    return {
        getSettings
    }
}