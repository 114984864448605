import {useEffect, useState} from "react";

export interface TkDrawerContextType {
    isShow: boolean;
    setIsShow: (isShow: boolean) => void
}

const TkDrawerContext = (): TkDrawerContextType => {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        document.body.classList[isShow ? 'add' : 'remove']('o-h');
    }, [isShow]);

    return {isShow, setIsShow};
};

export default TkDrawerContext
