import React, {FC, memo, useEffect, useRef} from "react";
import {useTkMedia, useTkRecommender} from "../../../context/TkContext";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCardProductSlider from "../TkCardProductSlider";
import TkCarousel from "../TkCarousel";
import {isBlank} from "../../../utils/string-utils";

const TkCarouselUserBasedProducts:FC<{
    productId?: string
}> = ({productId}) => {
    const {isMediaXs, isMediaSm} = useTkMedia()
    const ref = useRef<HTMLDivElement>()
    const {loadCartBasedRecommender, isLoadingCartBasedRecommender, cartBasedRecommender} = useTkRecommender()
    const title = 'Produtos relacionados'

    useEffect(() => {
        let observer: any;

        if (ref.current && isIntersectionObserver() && !isBlank(productId)) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(ref.current)
                            await loadCartBasedRecommender(productId, 25)
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(ref.current);
        }

        return () => {
            if (observer?.unobserve) observer.unobserve(ref.current);
        };
    }, [ref, productId]);

    if (isBlank(productId)) return null

    return <>
        <div ref={ref}/>

        {cartBasedRecommender?.length > 0 && <>

            <div>
                {isMediaXs || isMediaSm ?
                    <>
                        <h2 className="logo">{title}</h2>
                        <TkCardProductSlider products={cartBasedRecommender}
                                             isLoadingProducts={isLoadingCartBasedRecommender}/>
                    </>
                    :
                    <TkCarousel.Root title={title}>
                        <div className='TkCarousel__padding'>
                            <TkCarousel.Product products={cartBasedRecommender}
                                            isShowSkeleton={isLoadingCartBasedRecommender}/>
                        </div>
                    </TkCarousel.Root>
                }
            </div>
        </>}
    </>
}

export default memo(TkCarouselUserBasedProducts)