import {useCallback} from "react";
import {NewsletterIn} from "../../models/newsletter";
import {httpPostGraphQL} from "../../utils/http-utils";
import {registerMutation} from "./queries";
import {sendAnalyticsEvent} from "../../utils/analytics-utils";

export interface TkNewsletterContextType {
    newsletterIn?: NewsletterIn | null
    signUp: (name: string, email: string) => Promise<NewsletterIn | null | undefined>
}

const TkNewsletterContext = (): TkNewsletterContextType => {
    const signUp = useCallback(async (name: string, email: string): Promise<NewsletterIn> => {
        try {
            let variables:any = {name, email};

            const {data: result} = await httpPostGraphQL({
                query: registerMutation,
                variables
            });

            if (result.errors) {
                return Promise.resolve({
                    error: true,
                    errorMessages: result.errors
                })
            } else {
                const {data} = result;
                sendAnalyticsEvent('Newsletter', 'session', 'SignUp');

                return Promise.resolve({
                    newsletterIn: {
                        newsletter: data.signUpNewsletter.newsletter
                    },
                    errorMessage: null,
                    error: false
                });
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    return {signUp};
};

export default TkNewsletterContext
