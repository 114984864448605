export interface GraphQLError {
    message: string
}
export interface GraphQLResult<T> {
    data?: T
    success?: boolean
    errors?: GraphQLError[]
}

export const transformGraphQLErrors = (errors: any[]): GraphQLError[] => {
    if (!errors?.length) {
        return []
    }

    return errors.map(({ message }) => ({ message }))
}