import React, {CSSProperties} from "react";
import './style.scss'
import TkLazyImage from "../TkLazyImage";

const TkProportionalImage: React.FC<{
    url: string
    alt?: string
    className?: string
    plainB?: string
    width?: number
    height?: number
    listenHeight?: (height: number) => void
    clickEvent?: any
    style?: CSSProperties
}> = ({url, alt, className = '', plainB, width, height, listenHeight, clickEvent, style = {}}) => {
    const imgStyle = {...style,
        height: '100%',
        width: '100%',
        paddingLeft: '.685rem',
        paddingRight: '.685rem'
    };

    const onLoad = (e: any) => {
        const bcr = e.target.getBoundingClientRect()

        if (listenHeight) listenHeight(bcr.width);
    }


    return <div className={className}>
        <TkLazyImage src={url}
                     className='im'
                     alt={alt}
                     plainB={plainB}
                     width={width}
                     height={height}
                     style={{
                         ...imgStyle,
                         objectFit: 'contain',
                         objectPosition: 'center',
                         aspectRatio: '1/1'
                     }}
                     onLoad={onLoad}
                     clickEvent={clickEvent ? clickEvent : ''}/>
    </div>
};

export default TkProportionalImage
