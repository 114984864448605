import {useCallback, useState} from "react";
import {httpPostGraphQL} from "../../utils/http-utils";
import {calculateCheckoutCostsQuery, getCheckoutInstalmentsQuery} from "./queries";
import {TkCheckoutCosts, TkCheckoutInstalmentInfo} from "../../models/checkout";

export interface TkCheckoutContextType {
  calculateCosts: (postalCode: string, method: string, installments: number) => Promise<TkCheckoutCosts>
  getInstallments: (postalCode: string) => Promise<TkCheckoutInstalmentInfo[]>
  setPaymentMethod(method: string): void
  paymentMethod: string
  setPaymentMethod(method: string): void
  setCreditMonths(months: number): void
  creditMonths: number
  isCalculatingCheckoutCosts: boolean
  checkoutCosts: TkCheckoutCosts
}

const TkCheckoutContextContext = () : TkCheckoutContextType => {
  const [paymentMethod, setPaymentMethod] = useState<string | null | undefined>('pix')
  const [creditMonths, setCreditMonths] = useState<number | null | undefined>(1)
  const [isCalculatingCheckoutCosts, setCalculatingCheckoutCosts] = useState<boolean>(false)
  const [checkoutCosts, setCheckoutCosts] = useState<TkCheckoutCosts>({
    totalWithFees: 0,
    instalmentsFee: 0,
    shipmentCost: 0,
    shipmentDeadLine: 0,
    withdrawDeadLine: 0,
    totalFees: 0,
  })

  const calculateCosts = useCallback(async (postalCode: string, method: string, instalments: number): Promise<TkCheckoutCosts> => {
    try {
      setCalculatingCheckoutCosts(true)
      const {data: result} = await httpPostGraphQL({
        query: calculateCheckoutCostsQuery,
        variables: {postalCode, method, instalments},
      })

      if (result.errors) return Promise.reject(result.errors)
      const {data} = result

      setCheckoutCosts(state => ({...state, ...data.calculateCheckoutCosts}))

      return data.calculateCheckoutCosts
    } catch (e) {
      console.error('Fail calculateCartCosts', e)
      return Promise.reject(e)
    } finally {
      setCalculatingCheckoutCosts(false)
    }
  }, [setCheckoutCosts, setCalculatingCheckoutCosts])

  const getInstallments = useCallback(async (postalCode: string) => {
    try {
      const {data: result} = await httpPostGraphQL({
        query: getCheckoutInstalmentsQuery,
        variables: {postalCode},
      })
      if (result.errors) return Promise.reject(result.errors)

      const {data} = result

      return data?.getCheckoutInstalments ?? []
    } catch (e) {
      console.error('Fail getCheckoutInstallments', e)
    }
  }, [])


  return {
    calculateCosts,
    getInstallments,
    setPaymentMethod,
    paymentMethod,
    setCreditMonths,
    creditMonths,
    checkoutCosts,
    isCalculatingCheckoutCosts,
  }
}
export default TkCheckoutContextContext
