
export enum TkEngagementFunnelEntryType {
  rec = 'rec',
  click = 'click',
  car = 'car',
  buy = 'buy',
}
export interface TkEngagementFunnelEntryModel {
  screen?: string
  dateTime?: Date
  productIds?: string[]
  type?: TkEngagementFunnelEntryType
}
