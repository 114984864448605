import React, {FC, useEffect, useRef, useState} from "react";
import {useTkProduct} from "../../../context/TkContext";
import {TTkBestClassesResult} from "../../../models/product";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCarousel from "../TkCarousel";

const TkCarouselBestClasses:FC = () => {
    const {getBestClasses} = useTkProduct();
    const refRelated = useRef<HTMLSpanElement>()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [bestClasses, setBestClasses] = useState<TTkBestClassesResult[]>([]);

    useEffect(() => {
        let observer: any;

        if (refRelated.current && isIntersectionObserver()) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(refRelated.current)
                            setLoading(true)
                            getBestClasses()
                                .then(setBestClasses)
                                .finally(() => setLoading(false))
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(refRelated.current);
        }

        return () => {
            if (observer?.unobserve && refRelated.current) observer.unobserve(refRelated.current);
        };
    }, [refRelated, setLoading]);

    return <>
        <span ref={refRelated}/>
        <TkCarousel.Root title='Você pode se interessar...'>
            <div className='TkCarousel__padding'>
                <TkCarousel.Class classes={bestClasses.slice(0, 10)} isShowSkeleton={isLoading}/>
            </div>
        </TkCarousel.Root>
    </>
}

export default TkCarouselBestClasses