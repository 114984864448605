import {Reducer, useCallback, useEffect, useReducer} from "react";
import {ITkClassModel, ITkFamilyModel, ITkGroupModel, ITkManufacturerModel} from "../../models/product";
import {capitalize} from "../../utils/string-utils";
import {useHistory, useLocation} from "react-router-dom";

enum TypeAction {
    CHANGE,
}

enum Target {
    MANUFACTURER = 'manufacturers',
    FAMILY = 'families',
    CLASS = 'classes',
    GROUP = 'groups',
}

const changeItem = (type: TypeAction, target: Target, ...ids: string[]) => ({
    type,
    target,
    ids
})

type Action = ReturnType<typeof changeItem>

type State = {
    selectedManufacturersIds?: string[]
    selectedFamiliesIds?: string[]
    selectedClassesIds?: string[]
    selectedGroupsIds?: string[]
}

const initialState: State = {
    selectedManufacturersIds: [],
    selectedFamiliesIds: [],
    selectedClassesIds: [],
    selectedGroupsIds: [],
}

const reducer = (state: State, action: Action): State => {
    const newState: any = {
        ...state
    }

    const {type, ids, target} = action
    const property = `selected${capitalize(target)}Ids`

    if (type === TypeAction.CHANGE) {
        newState[property] = Array.from(new Set(ids).values())
    }

    return newState
}

export const useClassifierFilter = () => {
    const history = useHistory()
    const location = useLocation()
    const {search} = location

    const [{
        selectedManufacturersIds,
        selectedClassesIds,
        selectedFamiliesIds,
        selectedGroupsIds
    }, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);

    function changeItem(target: Target, ...ids: string[]) {
        const searchParameters = new URLSearchParams(search);
        const paramName = `${target}Ids`
        let newParams = new Set(ids ?? [])

        const newValue = Array.from(newParams.values()).join(',')

        if (newValue === '') {
            searchParameters.delete(paramName)
            if (target === Target.FAMILY) {
                searchParameters.delete(`${Target.CLASS}Ids`)
                searchParameters.delete(`${Target.GROUP}Ids`)
            } else if (target === Target.CLASS) {
                searchParameters.delete(`${Target.GROUP}Ids`)
            }
        } else searchParameters.set(paramName, newValue)

        history.push(`${location.pathname}?${searchParameters.toString()}`);
    }

    const getParamsIds = useCallback((paramName: string): string[] => {
        const searchParameters = new URLSearchParams(search);
        const ids = searchParameters.get(paramName)

        return !!ids ? ids.split(',') : []
    }, [search])

    const isSelected = useCallback((id: string, target: Target): boolean => {
        return getParamsIds(`${target}Ids`).includes(id)
    }, [getParamsIds])

    useEffect(() => {
        const [manufacturerIds, familyIds, classIds, groupIds] = [
            getParamsIds(`${Target.MANUFACTURER}Ids`),
            getParamsIds(`${Target.FAMILY}Ids`),
            getParamsIds(`${Target.CLASS}Ids`),
            getParamsIds(`${Target.GROUP}Ids`),
        ]

        dispatch({type: TypeAction.CHANGE, target: Target.MANUFACTURER, ids: manufacturerIds})
        dispatch({type: TypeAction.CHANGE, target: Target.FAMILY, ids: familyIds})
        dispatch({type: TypeAction.CHANGE, target: Target.CLASS, ids: classIds})
        dispatch({type: TypeAction.CHANGE, target: Target.GROUP, ids: groupIds})

    }, [getParamsIds])

    return {
        selectedManufacturersIds,
        selectedFamiliesIds,
        selectedClassesIds,
        selectedGroupsIds,
        selectManufacturer: (...manufacturers: ITkManufacturerModel[]) => changeItem(Target.MANUFACTURER, ...manufacturers.map(m => m._id)),
        selectFamily: (...families: ITkFamilyModel[]) => changeItem(Target.FAMILY, ...families.map(f => f._id)),
        selectClass: (...classes: ITkClassModel[]) => changeItem(Target.CLASS, ...classes.map(c => c._id)),
        selectGroup: (...groups: ITkGroupModel[]) => changeItem(Target.GROUP, ...groups.map(g => g._id)),
        isSelectedManufacturer: (manufacturer: ITkManufacturerModel) => isSelected(manufacturer._id, Target.MANUFACTURER),
        isSelectedFamily: (family: ITkFamilyModel) => isSelected(family._id, Target.FAMILY),
        isSelectedClass: (clazz: ITkClassModel) => isSelected(clazz._id, Target.CLASS),
        isSelectedGroup: (group: ITkGroupModel) => isSelected(group._id, Target.GROUP),
    }
}
