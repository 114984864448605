import React, {CSSProperties, memo} from "react";
import './style.scss'

const TkSkeleton: React.FC<{
    height?: any
    width?: any
    style?: CSSProperties
}> = ({height, width, style = {}}) => {
    return <div className='TkSkeleton' style={{
        height: height,
        width: width,
        ...style
    }}/>
};

export default memo(TkSkeleton);
