import React, {CSSProperties, FC, memo} from "react";
import './style.scss'

const TkDivider:FC<{
    text?: string
    className?: string
    style?: CSSProperties;
}> = ({text, className, style = {}}) => {
    return <div className={`TkDivider ${className}`} style={style}>
        <hr/>
        {text ? <span>{text}</span> : null}
    </div>
}

export default memo(TkDivider)