import React, {memo} from "react";
import {ITkProductQuantityModel} from "../../../models/product";

const TkAvailabilityLabel: React.FC<{
  item: ITkProductQuantityModel
}> = ({ item }) => {
  const product = item.product
  const quantity = item.quantity
  const isAvailable = product.stock || product.leadTime
  const isPartialBackorder = product.stock && product.leadTime && quantity > product.stock
  const isBackorder = !product.stock && product.leadTime

  return <>
    <div className="TkProductDetailView__availabilityWrapper">
      {isAvailable && item.price ? (
        <>
          {!!product.stock && <span className="TkProductDetailView__availability">
              {isPartialBackorder && product.stock || ''} Em Estoque
            </span>}
          {(isPartialBackorder || isBackorder) && <span className="TkProductDetailView__backorder">
            {isPartialBackorder && (quantity - product.stock) || ''} Sob encomenda
           </span>}
        </>) : (
          <span className="TkProductDetailView__out-of-stock"> Indisponível</span>
        )
      }
    </div>

  </>
};

export default memo(TkAvailabilityLabel)
