import React, {FC, memo} from "react";

const TkListQuantityOptions: FC<{
    stock?: number
}> = ({stock= 30}) => {
    const maxProducts = 2500

    if(stock == 0) stock = 30
    const items = Array(stock > maxProducts ? maxProducts : stock).fill(0)

    return <>
        {<option value='more'>Outro</option>}
        {items.map((item, idx) => <option key={idx} value={idx + 1}>Qtd: {idx + 1}</option>)}
    </>
};

export default memo(TkListQuantityOptions)
