import {useCallback, useReducer} from "react";

enum Actions {
    toggle_balloon,
    toggle_icon,
}

const hideShowBalloon = (isShow = false) => ({
    type: Actions.toggle_balloon,
    payload: isShow
})


const hideShowIcon = (isShow = false) => ({
    type: Actions.toggle_icon,
    payload: isShow
})

type Action = ReturnType<typeof hideShowBalloon> | ReturnType<typeof hideShowIcon>

type State = {
    isShowBalloon: boolean,
    isShowIcon: boolean,
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case Actions.toggle_balloon:
            return {
                ...state,
                isShowBalloon: action.payload
            }
        case Actions.toggle_icon:
            return {
                ...state,
                isShowIcon: action.payload
            }
        default:
            return state
    }
}

export interface TkWhatsappBalloonContextType {
    isShowBalloon: boolean,
    isShowIcon: boolean,
    setShowBalloon: (isShow: boolean) => void,
    setShowIcon: (isShow: boolean) => void,
}
export const TkWhatsappBalloonContext = (): TkWhatsappBalloonContextType =>  {
    const [{isShowBalloon, isShowIcon}, dispatch] = useReducer(reducer, {
        isShowBalloon: false,
        isShowIcon: true
    })

    const setShowBalloon = useCallback((isShowBalloon = true) => {
        dispatch({
            type: Actions.toggle_balloon,
            payload: isShowBalloon
        })
    }, [dispatch])

    const setShowIcon = useCallback((isShowIcon = true) => {
        dispatch({
            type: Actions.toggle_icon,
            payload: isShowIcon
        })
    }, [dispatch])

    return {
        isShowBalloon,
        isShowIcon,
        setShowBalloon,
        setShowIcon
    }
}
