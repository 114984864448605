import {useState} from "react";

export interface TkBreadcrumbContextType {
    breadcrumb?: Breadcrumb[] | null;
    setBreadcrumb: (list: Breadcrumb[]) => void
}

export interface Breadcrumb {
    label: string
    url: string
}

const TkBreadcrumbContext = (): TkBreadcrumbContextType => {
    const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>();


    return {breadcrumb, setBreadcrumb};
};

export default TkBreadcrumbContext
