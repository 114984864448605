import React, {FC, memo, useEffect, useRef} from "react";
import {useTkMedia, useTkRecommender, useTkUser} from "../../../context/TkContext";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCardProductSlider from "../TkCardProductSlider";
import TkCarousel from "../TkCarousel";

const TkCarouselUserBasedProducts:FC = () => {
    const {userAuth} = useTkUser()
    const {isMediaXs, isMediaSm} = useTkMedia()
    const ref = useRef<HTMLDivElement>()
    const {loadUserBasedRecommender, isLoadingUserBasedRecommender, userBasedRecommender} = useTkRecommender()

    useEffect(() => {
        let observer: any;

        if (ref.current && isIntersectionObserver() && userAuth?.user) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(ref.current)
                            await loadUserBasedRecommender()
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(ref.current);
        }

        return () => {
            if (observer?.unobserve) observer.unobserve(ref.current);
        };
    }, [ref, userAuth]);

    return <>
        <div ref={ref}/>

        {userAuth?.user && userBasedRecommender?.length > 0 && <>
            <h2 className="logo">Sugestões para você</h2>
            <div>
                {isMediaXs || isMediaSm ?
                    <TkCardProductSlider products={userBasedRecommender}
                                         isLoadingProducts={isLoadingUserBasedRecommender} />
                    :
                    <TkCarousel.Root title='Produtos para você'>
                        <div className='TkCarousel__padding'>
                            <TkCarousel.Product products={userBasedRecommender}
                                            isShowSkeleton={isLoadingUserBasedRecommender}/>
                        </div>
                    </TkCarousel.Root>
                }
            </div>
        </>}
    </>
}

export default memo(TkCarouselUserBasedProducts)