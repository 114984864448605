import React, {FC, memo} from "react";
import {Link} from "react-router-dom";
import {tk_route_search} from "./index";

const TkHasNoResults: FC = () => {
    return <>
        <h3>Não encontramos resultados para os filtros de pesquisa selecionados</h3>
        <br/>
        <p>Clique <Link title='Clique para trazer todos resultados' to={tk_route_search}>aqui</Link> para limpar os filtros de pesquisa e continuar sua procura.</p>
        <br/>
        <p className='c-a-g'><b>Abaixo listamos alguns dos nossos produtos mais visitados:</b></p>
        <br/>
    </>
};

export default memo(TkHasNoResults)
