import React, {FC, useEffect, useRef, useState} from "react";
import {useTkMedia, useTkProduct} from "../../../context/TkContext";
import {ProductListResult} from "../../../context/TkProductContext";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCardProductSlider from "../TkCardProductSlider";
import TkCarousel from "../TkCarousel";

const TkCarouselBestProducts:FC = () => {
    const {isMediaXs, isMediaSm} = useTkMedia()
    const refRelated = useRef<HTMLDivElement>()
    const {getProductsOutdoor} = useTkProduct()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<ProductListResult>();
    const [isNoShow, setNoShow] = useState<boolean>(false)

    useEffect(() => {
        let observer: any;

        if (refRelated.current && isIntersectionObserver()) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(refRelated.current)
                            setLoading(true)
                            getProductsOutdoor('__weekly_best_sales__', 40)
                                .then((res) => {
                                    setProducts(res)
                                    setNoShow(!res.total)
                                })
                                .finally(() => setLoading(false))
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(refRelated.current);
        }

        return () => {
            if (observer?.unobserve && refRelated.current) observer.unobserve(refRelated.current);
        };
    }, [refRelated, setLoading, setNoShow]);

    const placeholder = Array(10).fill(null)

    const backupProducts = isLoading
        ? placeholder
        : products?.items;

    if (isNoShow) return null;

    return <>
        <span ref={refRelated}/>
        {isMediaXs || isMediaSm ?
            <TkCardProductSlider products={backupProducts || []} isLoadingProducts={isLoading}/>
            :
            <TkCarousel.Root title='Destaques do Mês'>
                <div className='TkCarousel__padding'>
                    <TkCarousel.Product products={backupProducts || []} isShowSkeleton={isLoading}/>
                </div>
            </TkCarousel.Root>
        }
    </>
}

export default TkCarouselBestProducts