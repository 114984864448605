export const availability = {
    IN_STOCK: 'IN_STOCK',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    BACKORDER: 'BACKORDER',
    DISCONTINUED: 'DISCONTINUED'
}

export const availabilityClasses : {[key: string]: string | undefined} = {
    IN_STOCK: 'availability',
    OUT_OF_STOCK: 'out-of-stock',
    BACKORDER: 'backorder',
    DISCONTINUED: 'discontinued'
}

export const availabilityLabels : {[key: string]: string | undefined} = {
    IN_STOCK: 'Em Estoque',
    OUT_OF_STOCK: 'Indisponível', // status naming here is kind of missleading
    BACKORDER: 'Sob Encomenda',
    DISCONTINUED: 'Produto descontinuado'
}