import {User} from "../models/user";
import {isBlank} from "./string-utils";

const FINGER_PRINT = `f9015900-3060-4cb0-8fe0-252704db0d15`;

export const saveAuthToken = (token?: string, fp?: string): void => {
    if (!isBlank(token)) {
        // @ts-ignore
        window[FINGER_PRINT] = token
    }
    if (!isBlank(fp)) DeviceStorage.put(FINGER_PRINT, fp);
};

export const destroyAuthToken = (): void => {
    // @ts-ignore
    delete window[FINGER_PRINT]
};

export const getAuthToken = (): string | null | undefined => {
    // @ts-ignore
    return window[FINGER_PRINT]
};

export const getFingerPrint = (): string | null | undefined => {
    return DeviceStorage.get(FINGER_PRINT)
};

export default class DeviceStorage {
    private static weakCache = new Map();

    private static get USER_KEY(): string {
        return '_userData'
    }

    private static get EMAIL_SIGNUP(): string {
        return '_emailSignup'
    }

    private static get POSTAL_CODE(): string {
        return '_postalCode'
    }

    static putEmailSignup(email: string): void {
        localStorage.setItem(this.EMAIL_SIGNUP, email);
        this.weakCache.set(this.EMAIL_SIGNUP, email);
    }

    static get getEmailSignup(): string | null {
        return this.get(this.EMAIL_SIGNUP);
    }

    static putPostalCode(postalCode: string): void {
        localStorage.setItem(this.POSTAL_CODE, postalCode);
        this.weakCache.set(this.POSTAL_CODE, postalCode);
    }

    static destroyPostalCode(): void {
        localStorage.removeItem(this.POSTAL_CODE);
        this.weakCache.delete(this.POSTAL_CODE);
    }

    static get getPostalCode(): string | null {
        return this.get(this.POSTAL_CODE);
    }

    static destroyEmailSignup(): void {
        this.destroy(this.EMAIL_SIGNUP);
    }

    static put(key: string, val: string): void {
        localStorage.setItem(key, val);
        this.weakCache.set(key, val);
    }

    static get(key: string): string | null {
        if (this.weakCache.has(key)) return this.weakCache.get(key);
        const val = localStorage.getItem(key);
        if (val) this.weakCache.set(key, val);

        return val
    }

    static destroy(key: string): void {
        localStorage.removeItem(key);
        this.weakCache.delete(key);
    }

    static putObject(key: string, obj: object): void {
        this.put(key, JSON.stringify(obj))
    }

    static getObject<T>(key: string): T | null {
        const result = this.get(key);
        return result ? JSON.parse(result) : null;
    }

    static saveUser(user: User): void {
        this.putObject(this.USER_KEY, user)
    }

    static destroyUser(): void {
        this.destroy(this.USER_KEY)
    }

    static get user(): User | null {
        return this.getObject(this.USER_KEY) as User
    }
}

(() => {

    if (getFingerPrint()) return;

    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,  (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    saveAuthToken(null, uuid)
})();
