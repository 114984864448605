import React, {memo, useState} from "react";
import {useTkNewsletter} from "../../../context/TkContext";
import {NewsletterIn} from "../../../models/newsletter";
import {isBlank, isEmail} from "../../../utils/string-utils";
import {useSimpleInput} from "../../../hooks/UseSimpleInput";
import TkSvgIcon from "../../../components/particles/TkSvgIcon";
import {animated as a, useTrail} from "react-spring";
import './style.scss'

interface StateAdd {
    errors: string[]
    hasErrors: boolean
    doing: boolean
    successAdd: boolean
}

const TkNewsLetter: React.FC = () => {
    const {signUp} = useTkNewsletter();
    const {value: name, bind: bindName, setValue: setNameValue} = useSimpleInput('');
    const {value: email, bind: bindEmail, setValue: setEmailValue} = useSimpleInput('');
    
    const initialState: StateAdd = {
        hasErrors: false,
        errors: [],
        doing: false,
        successAdd: false
    };

    const [state, setState] = useState<StateAdd>({...initialState});

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(state.errors.length, {
        config,
        opacity: state.hasErrors ? 1 : 0,
        from: {opacity: 0},
    });

    function submitForm(e: any) {
        e.preventDefault();
        let newState = {...initialState};
    
        if (isBlank(name)) {
            newState = {
                ...newState,
                hasErrors: true,
                errors: [...newState.errors, 'Preencha o nome']
            }
        }
    
        if (isBlank(email) || !isEmail(email)) {
            newState = {
                ...newState,
                hasErrors: true,
                errors: [...newState.errors, isBlank(email) ? 'Preencha o e-mail' : 'E-mail inválido']
            }
        }
    
        setState(newState);
    
        if (!newState.hasErrors) {
            setState({
                ...newState,
                doing: true
            });
            
            signUp(name, email)
            .then((result: NewsletterIn) => {
                if (result.error) {
                    const messages = result.errorMessages instanceof Array ? result.errorMessages.map(e => e.message) : [];
                    newState.hasErrors = true;
                    newState.errors = messages;
                    setState(newState);
                } else {
                    newState = {
                        ...newState,
                        hasErrors: false,
                        errors: [],
                        successAdd: true,
                        doing: false
                    };
                    setState(newState);
                }
            });
        }

        setTimeout(() => {
            setState({...initialState});
            setNameValue('');
            setEmailValue('');
        }, 4000);
    }

    return (
        <section className='TkNewsLetter'>
            <h2 className='TkNewsLetter__title'>Cadastre-se e receba ofertas exclusivas</h2>
            <form className='TkNewsLetter__form' method='POST' onSubmit={submitForm} noValidate>
                <div className='TkNewsLetter__input-container'>
                    <input 
                        type='text' 
                        className='form-control form-control-sm' 
                        placeholder='Digite seu nome'
                        {...bindName} 
                        required 
                    />
                    <input 
                        type='email' 
                        className='form-control form-control-sm' 
                        placeholder='Digite seu e-mail' 
                        {...bindEmail}
                        required 
                    />
                    <button 
                        type='submit' 
                        className='button'
                        disabled={state.doing}
                    >
                        {state.doing ? 
                            <TkSvgIcon className='rotate-1-seg' icon='sync-solid'/> :
                            <span>Enviar</span>
                        }
                    </button>
                </div>
                <div className='TkNewsLetter__form-msgs'>
                    {trail.map((props, index) => (
                        <a.div
                            key={index}
                            className='alert alert-danger'
                            style={props}>
                            {state.errors[index]}
                        </a.div>
                    ))}

                    {state.successAdd && <div className='alert alert-success'>Dados cadastrados com sucesso!</div>}
                </div>
            </form>
        </section>
    );
};


export default memo(TkNewsLetter);
