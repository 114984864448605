import {useCallback, useState} from "react";
import history from "../utils/history-utils";

import {tk_route_something_wrong} from "../views/Tk500View";

/**
 * Erros são definidos por erros de
 */
const errorTypes = {
    APP_00001: {
        code: 'APP-00001',
        message: 'Ops... Aconteceu algum problema desconhecido.'
    },
    NET_00001: {
        code: 'NET-00001',
        message: 'Ops... Aconteceu algum problema na comunicação com o servidor. Verifique seu acesso à internet.'
    }
}

export interface TkAppErrorType {
    messages?: string[]
    code?: string,
    defaultError?: any | null
}

export interface TkAppErrorsContextType {
    currentError?: TkAppErrorType | null;
    goToPageError: (error?: any) => void
}

const TkAppErrorsContext = (): TkAppErrorsContextType => {
    const [currentError, setCurrentError] = useState<TkAppErrorType>(null);

    const goToPageError = useCallback((error: any) => {

        let treatError: TkAppErrorType;

        if (error.isAxiosError) {
            treatError = {
                code: errorTypes.NET_00001.code,
                messages: [errorTypes.NET_00001.message],
                defaultError: error
            }
        } else {
            treatError = {
                code: errorTypes.APP_00001.code,
                messages: [errorTypes.APP_00001.message],
                defaultError: error
            }
        }

        setCurrentError(treatError);

        const timer = setTimeout(() => history.push(tk_route_something_wrong), 200);

        return () => clearTimeout(timer);
    }, [])


    return {
        currentError,
        goToPageError
    };
};

export default TkAppErrorsContext
