import React, {FC, memo} from "react";
import {useTkMedia} from "../../../context/TkContext";
import Slider, {Settings} from "react-slick";
import {Link} from "react-router-dom";
import {TkRouteSearchBuilder} from "../../../views/TkSearchView";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import {TTkBestManufacturersResult} from "../../../models/product";
import _CarouselSkeleton from "./_carouselSkeleton";
import {_useTkCarousel} from "./_useTkCarousel";
import TkSkeleton from "../../particles/TkSkeleton";

const Card: FC<{
    manufacturer: TTkBestManufacturersResult
}> = ({manufacturer}) => {
    return <div className='TkCarousel__card-manufacturer'>
        <Link title={manufacturer.name}
              to={new TkRouteSearchBuilder()
                  .addManufacturer({_id: manufacturer._id})
                  .build()
              }>
            <img src={manufacturer.image} alt={manufacturer.name} loading='lazy'/>
        </Link>
        <div className='TkCarousel__card-manufacturer-products'>
            {manufacturer.products.map((product, idx) => {
                return <Link key={idx}
                             title={product.name}
                             to={tk_route_product_detail(product)}>
                    <img src={product.image} alt={product.name} loading='lazy'/>
                </Link>
            })}
        </div>
    </div>
}

const TkCarouselManufacturer: FC<{
    manufacturers: TTkBestManufacturersResult[]
    isShowSkeleton?: boolean
}> = ({manufacturers, isShowSkeleton}) => {
    const {isMediaXs, isMediaSm} = useTkMedia();
    const {getSettings} = _useTkCarousel()
    const settings: Settings = getSettings()


    if (isMediaXs || isMediaSm) {
        return <div className='TkCarousel__horizontal-scroll'>
            {isShowSkeleton ?
                Array.from({length: 2}).map((_, idx) => {
                    return <TkSkeleton key={idx} style={{
                        minWidth: 250,
                        height: 230,
                        backgroundImage: 'linear-gradient(-90deg, #fff 0%, #F8F8F8 50%, #fff 100%)'
                    }}/>
                })
                :
                manufacturers.map((manufacturer, idx) => {
                    return <Card key={idx} manufacturer={manufacturer}/>
                })
            }
        </div>
    }

    return isShowSkeleton ? <_CarouselSkeleton/> : <Slider {...settings}>
        {manufacturers.map((manufacturer, idx) => {
            return <div key={idx}>
                <Card manufacturer={manufacturer}/>
            </div>
        })}
    </Slider>
}


export default memo(TkCarouselManufacturer)