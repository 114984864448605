import React, {memo, useEffect} from "react";

export const tk_var_iugu_script_id = '_scriptIugu_';

const TkPaymentInitializer: React.FC<{
    isLoadedCallBack?: (isOk: boolean) => void
}> = ({isLoadedCallBack}) => {

    useEffect(() => {
        let script = document.getElementById(tk_var_iugu_script_id);

        if (!script) {
            script = document.createElement('script');
            script.id = tk_var_iugu_script_id;

            // @ts-ignore
            script.async = true;
            // @ts-ignore
            script.src = 'https://js.iugu.com/v2';

            if (isLoadedCallBack) script.onload = () => isLoadedCallBack(true);
            document.head.appendChild(script);
        } else {
            if (isLoadedCallBack) isLoadedCallBack(true);
        }
    }, []);


    return <></>
};

export default memo(TkPaymentInitializer)
