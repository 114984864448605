const defaultCart = `_id totalValue totalQuantity createdAt updatedAt
                        items {
                          quantity price total isWithdraw
                          product {
                            _id name mainName thumbnail manufacturerName manufacturerId manufacturerReference promoPrice stock leadTime unitCommercialName isWithdraw externalId minimumSaleQuantity controlMultiplicity
                            hierarchies{
                              division{ _id name}
                              family{ _id name}
                              class{ _id name}
                              group{ _id name}
                            }
                          }
                      }`;
export const currentCartQuery = `query { getCurrentCart {${defaultCart}} }`;
export const updateCartMutation = `mutation ($productId:String!, $quantity: Int!, $addOrUpdate: Boolean!, $isWithdraw: Boolean, $isCameFromRecommender: Boolean) {
    updateCart(productId: $productId, quantity: $quantity, addOrUpdate: $addOrUpdate, isWithdraw: $isWithdraw, isCameFromRecommender: $isCameFromRecommender ) {${defaultCart}}
}`;
export const removeFromCartMutation = `mutation ($productId:String!) {
    removeFromCart(productId: $productId) {${defaultCart}}
}`;
export const copyListToCartMutation = `mutation ($listId:String!) {
    copyListToCart(listId: $listId) {${defaultCart}}
}`;

export const destroyCartMutation = `mutation {destroyCart{${defaultCart}}}`;

export const getCartExcelQuery = `query {
                            getCartExcel { base64 fileName extension}
                        }`;

export const getCartPdfQuery = `query {
                            getCartPdf{ base64 fileName extension}
                        }`;

export const validateProductsAvailabilityQuery = `query {
                            validateProductsAvailability { success result{
                                product {_id name}
                                cartQuantity
                                currentAvailability
                            }}
                        }`;
