import React, {memo} from "react";
import "../TkModal/style.scss";

import TkModal, {TkModalActions, TkModalBody} from "../TkModal";
import {ITkProductModel} from "../../../models/product";

const TkConfirmBackorderModal: React.FC<{
  close(event?: any): void;
  onConfirm: Function;
  product: ITkProductModel;
  show: boolean;
  quantity: number;
}> = ({ close, onConfirm, product, quantity, show = false }) => {
  return (
    <TkModal show={show} title="Encomenda" size="sm" close={close}>
      <TkModalBody>
        <span>{`O prazo de entrega de ${
          quantity - product?.stock
        } unidades do produto é de `}</span>
        <b>{`${product?.leadTime} dias.`}</b>
        <span>{" Tem certeza que deseja adicionar ao carrinho?"}</span>
      </TkModalBody>

      <TkModalActions>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onConfirm()}
        >
          Encomendar
        </button>
        <button type="button" className="btn btn-secondary" onClick={close}>
          Fechar
        </button>
      </TkModalActions>
    </TkModal>
  );
};

export default memo(TkConfirmBackorderModal);
