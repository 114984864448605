import React, {FC, useEffect, useRef, useState} from "react";
import {isIntersectionObserver} from "../../../utils/utils";
import manufacturers from "../../../assets/json/manufacturers.json";
import {getS3Object} from "../../../utils/http-utils";
import {useTkProduct} from "../../../context/TkContext";
import {TTkBestManufacturersResult} from "../../../models/product";
import TkCarousel from "../TkCarousel";

const TkCarouselBestManufacturers:FC = () => {
    const {getBestProductsManufacturers} = useTkProduct();
    const refRelated = useRef<HTMLSpanElement>()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [bestManufacturers, setBestManufacturers] = useState<TTkBestManufacturersResult[]>([]);

    const brands = manufacturers.map((manufacturer) => {
        return {
            _id: manufacturer._id,
            name: manufacturer.name,
            image: getS3Object(manufacturer.imageSrc),
        }
    })

    useEffect(() => {
        let observer: any;

        if (refRelated.current && isIntersectionObserver()) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(refRelated.current)
                            setLoading(true)
                            getBestProductsManufacturers(...brands.map((brand) => brand._id))
                                .then((result) => {
                                    setBestManufacturers(result.map((manufacturer) => {
                                        const image = brands.find((brand) => brand._id === manufacturer._id)?.image
                                        return {
                                            ...manufacturer,
                                            image
                                        }
                                    }))
                                })
                                .finally(() => setLoading(false))
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(refRelated.current);
        }

        return () => {
            if (observer?.unobserve && refRelated.current) observer.unobserve(refRelated.current);
        };
    }, [refRelated, setLoading]);


    return <>
        <span ref={refRelated}/>
        <TkCarousel.Root title='Marcas em destaque'>
            <div className='TkCarousel__padding'>
                <TkCarousel.Manufacturer isShowSkeleton={isLoading} manufacturers={bestManufacturers}/>
            </div>
        </TkCarousel.Root>
    </>
}

export default TkCarouselBestManufacturers;