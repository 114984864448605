import React, {FC, memo, ReactNode, useEffect} from "react";
import './style.scss';
import {animated as a, config, useSpring} from "react-spring";
import {useTkWhatsAppBalloon} from "../../../context/TkContext";

export const TkModalBody: FC = ({children}) => {
    return <div className='TkModal__body'>{children}</div>
}

export const TkModalActions: FC = ({children}) => {
    return <div className='TkModal__actions'>{children}</div>
}

const TkModal: FC<{
    size?: string | null
    show: boolean
    isHiddenBars?: boolean
    title?: string | null
    close(event?: any): void
    children: ReactNode
}> = ({size = 'xs', show = false, isHiddenBars = true, title = 'modal', close, children}) => {
    const {setShowIcon} = useTkWhatsAppBalloon()

    const propsContainer = useSpring({
        opacity: show ? 0 : 1,
        from: {opacity: show ? .5 : 1, transform: `translate(-50%, ${show ? 0 : 100}px)`},
        to: {opacity: show ? 1 : .5, transform: `translate(-50%, ${show ? 100 : 0}px)`},
        config: config.stiff
    });

    const propsOverlay = useSpring({
        opacity: show ? 0 : 1,
        from: {opacity: show ? 0 : 1},
        to: {opacity: show ? 1 : 0}
    });

    useEffect(() => {
        if (isHiddenBars) document.body.classList[show ? 'add' : 'remove']('o-h');
        setShowIcon(!show)
    }, [show, setShowIcon]);

    if (!show) return <></>;

    return <a.div style={propsOverlay} className='TkModal__overlay'>
        <a.div style={propsContainer} className={`TkModal__container TkModal__container--${size}`}>
            <div className='TkModal__title'>
                <span className='TkModal__title-label'>{title}</span>
                <span className='TkModal__close' onClick={close}>&times;</span>
            </div>
            {children}
        </a.div>
    </a.div>;
};

export default memo(TkModal)
