import React from "react";
import './style.scss';

const TkDots: React.FC<{
    count: number,
    active: number,
    setCurrent?: (index: number) => void
}> = ({count, active, setCurrent}) => {

    function dots() {
        const ds = [];

        for (let i = 0; i < count; i++) {
            ds.push(<span key={i}
                          onClick={() => setCurrent && setCurrent(i)}
                          className={`TkDots__dot ${active === i ? 'TkDots__dot--active' : ''}`}/>)
        }
        return ds;
    }

    return <div className='TkDots__dots'>{dots()}</div>
};

export default TkDots
