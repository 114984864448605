import {useState} from "react";

export const useSimpleInput = (initialValue?: any) => {
    const [value, setValue] = useState<string>(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(""),
        bind: {
            value,
            onChange: (e: any) => {
                setValue(e.target.value);
            }
        }
    };
};
