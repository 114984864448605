import React, {FC, memo, PropsWithChildren} from "react";
import {isBlank} from "../../../utils/string-utils";


const TkCarouselRoot: FC<PropsWithChildren<{
    title?: string
}>> = ({children, title}) => {
    return <div className='TkCarousel__slider-container'>
        {!isBlank(title) ? <h1 className="carousel-title">{title}</h1> : null}
        {children}
    </div>
}

export default memo(TkCarouselRoot)