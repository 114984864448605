import React, {memo} from "react";
import {ITkAttributeModel, ITkProductExtraInfoModel} from "../../../models/product";
import './style.scss';
import TkSkeleton from "../../particles/TkSkeleton";
import {isBlank} from "../../../utils/string-utils";

const TkProductAttributes: React.FC<{
    attributes: ITkAttributeModel[]
    extraInfo?: ITkProductExtraInfoModel
    showSkeleton?: boolean
}> = ({attributes, extraInfo, showSkeleton}) => {

    if (showSkeleton) {
        const skeletons = Array(9).fill(null)

        return <dl className='TkProductAttributes'>
            {skeletons.map((_, idx) => {
                return (
                    <React.Fragment key={idx}>
                        <dt><TkSkeleton width='40%' height={13} style={{display: 'inline-block', margin: 0}}/></dt>
                        <dd><TkSkeleton width='45%' height={13} style={{display: 'inline-block', margin: 0}}/></dd>
                    </React.Fragment>
                );
            })}
        </dl>
    }

    if(extraInfo){
        let dimensions = extraInfo.height + 'x' + extraInfo.width + 'mm; ' + extraInfo.weight + ' Kg'
        attributes.push({name: "Dimensões", value: dimensions, unitMeasure: "", unitMeasureSymbol: ""})
    }
    return <dl className='TkProductAttributes'>
        {attributes && attributes.map((a) => {
            return (
                <React.Fragment key={`${a.name}a.value.toLowerCase()`}>
                    <dt>{a.name}</dt>
                    <dd>{a.value.toLowerCase()}{isBlank(a.unitMeasure) ? '' : ` (${a.unitMeasure})`}</dd>
                </React.Fragment>
            );
        })}
    </dl>;
};

export default memo(TkProductAttributes)
