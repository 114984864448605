import './style.scss'
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import TkSvgIcon from "../../particles/TkSvgIcon";
import {useTkAppErrors, useTkMedia, useTkProduct} from "../../../context/TkContext";
import {isBlank} from "../../../utils/string-utils";
import {Link, useHistory, useLocation} from "react-router-dom";
import {tk_route_term} from "../../../views/TkSearchView";
import {isMobile} from "../../../utils/utils";

const ListItems: React.FC<{
    items: string[],
    selectedIndex: number
}> = memo(({items, selectedIndex}) => {
    return <ul className="autocomplete-options">
        {items.map((opt, idx) => {
            return <li key={`${idx}${opt}`}>
                <Link to={tk_route_term(opt)}
                      className={'ellipsis ' + (idx === selectedIndex ? 'selected' : '')}>{opt}</Link>
            </li>
        })}
    </ul>
});

const TkTopSearch: React.FC = () => {
    const spanId = 'hiddenSpan';
    const {isMediaXs, isMediaSm} = useTkMedia();
    const {autoComplete, state: {divisions}} = useTkProduct();
    const {goToPageError} = useTkAppErrors();
    const defaultGrid = isMediaXs || isMediaSm ? '90% auto' : 'minmax(auto, 5%) auto 50px';
    const [gridSize, setGridSize] = useState({gridTemplateColumns: defaultGrid});
    const [selectValue, setSelectValue] = useState<string>('');
    const [searching, setSearching] = useState(false);
    const [focus, setFocus] = useState(false);
    const [index, setIndex] = useState(-1);
    const [showOptions, setShowOptions] = useState(false);
    const [callToShow, setCallToShow] = useState(false);
    const [term, setTerm] = useState('');
    const [forceSearch, setForceSearch] = useState(null);
    const [items, setItems] = useState<string[]>([]);
    const history = useHistory();
    const location = useLocation();
    const inputRef = useRef<HTMLInputElement>();
    const selectRef = useRef<HTMLSelectElement>();

    useEffect(() => {
        setGridSize({gridTemplateColumns: defaultGrid});
    }, [isMediaSm, isMediaXs]);

    useEffect(() => {
        let timer: any = null;
        if (callToShow) setShowOptions(true);
        else timer = setTimeout(() => setShowOptions(false), 300);

        return () => clearTimeout(timer);
    }, [callToShow]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const term = params.get('term');
        if (!isBlank(term)) setTerm(term);
        else setTerm('');

        const divisionId = params.get('divisionId');
        let selectedDivision: string;
        if (!isBlank(divisionId) && selectRef) selectedDivision = divisionId

        setSelectValue(isBlank(selectedDivision) ? '' : selectedDivision);

        const select = divisions ? divisions.filter(ci => ci._id === selectedDivision) : [];
        let label: string = '';
        if (select.length > 0) label = select[0].name;

        resizeSelect(label);
    }, [location, selectRef, divisions]);

    const filter = useCallback((value: string) => {

        setSearching(true);
        let divisionId: string = !!selectValue ? selectValue : null;

        autoComplete(value, divisionId)
            .then(({suggestions}) => setItems(suggestions.map(s => s.text)))
            .catch(goToPageError)
            .finally(() => setSearching(false));

        setCallToShow(true);
        setIndex(-1);
    }, [setSearching, selectValue, setCallToShow, setIndex, setItems]);

    useEffect(() => {
        let timer: any, text: any;

        if (inputRef && inputRef.current) {
            text = inputRef.current.value;
            const p = new URLSearchParams(location.search)
            const term = p.get('term')

            if (!isBlank(text) && term !== text) {
                timer = setTimeout(() => filter((text || '').trim()), 300);
            } else {
                setItems([])
            }
        }

        return () => clearTimeout(timer);
    }, [inputRef, forceSearch, filter]);

    const resizeSelect = useCallback(label => {
        if (document != null && !isBlank(label) && !(isMediaXs || isMediaSm)) {
            const span = document.createElement('span');
            span.innerText = label;
            span.id = spanId;
            span.style.position = 'absolute';
            span.style.visibility = 'hidden';
            span.style.top = '-7000px';
            span.style.zIndex = '-7000';
            document.body.appendChild(span);

            const width = (span.getBoundingClientRect().width + 30) + '';
            span.remove();

            setGridSize({gridTemplateColumns: `${parseInt(width)}px auto 50px`});
        } else {
            setGridSize({gridTemplateColumns: defaultGrid});
        }

    }, [setGridSize, isMediaSm, isMediaXs]);

    const onChange = useCallback(({target}: any) => {
        const val = target.value;
        setSelectValue(val);

        let label: string;

        if (document != null && val !== -1) {
            const select = divisions ? divisions.filter(ci => ci._id === val) : [];

            label = select.length > 0 ? select[0].name : null;
        }

        resizeSelect(label);
    }, [setSelectValue, divisions, resizeSelect])

    const moveSelection = useCallback((isUp = true) => {
        const opts = items;
        if (opts.length === 0) return;

        let idx = index + (isUp ? -1 : 1);

        if (idx > opts.length - 1) idx = 0;
        else if (idx < 0) idx = opts.length - 1;

        setIndex(idx);
    }, [setIndex, index, items]);

    const goToSearch = useCallback(() => {
        setItems([]);
        setShowOptions(false);

        let query = inputRef.current.value;

        if (index > -1) query = items[index];

        const select = divisions ? divisions.filter(ci => ci._id === selectValue) : [];
        let route = !!selectValue ? tk_route_term(query, selectValue, 'division', select[0].name) : tk_route_term(query);
        history.push(route)
    }, [setItems, selectValue, inputRef, divisions, index]);

    const onInputKeyDown = useCallback((e: any) => {
        switch (e.nativeEvent.key) {
            case 'ArrowDown':
                moveSelection(false);
                break;
            case 'ArrowUp':
                moveSelection();
                break;
            case 'Enter':
                goToSearch();
        }
    }, [moveSelection, goToSearch]);

    const onInputKeyUp = useCallback((e: any) => {

        if (isMobile.any()) {
            setForceSearch(Date.now());
        } else {
            const k = e.key;
            if ((/[a-zA-Z0-9-_ ]/i.test(k) && k.length === 1) || k === 'Backspace') {
                setForceSearch(Date.now());
            }
        }
    }, [setForceSearch]);

    const onInputChange = useCallback((e: any) => {
        setTerm(e.target.value);
        if (e.type === 'input' && e.target.value === '') setForceSearch(Date.now());
    }, [setForceSearch, setTerm]);

    const onInputBlur = useCallback(() => {
        setCallToShow(false);
        setFocus(false);
    }, [setCallToShow, setFocus]);

    const onInputFocus = useCallback(() => {
        setForceSearch(Date.now());
        setFocus(true);
    }, [setForceSearch, setFocus]);

    const onInputPaste = useCallback((e: any) => {
        const value = e.clipboardData.getData('text');

        if (value) setForceSearch(Date.now());
    }, [setForceSearch]);

    return <div className={`TkTopSearch ${focus ? 'TkTopSearch--active' : ''}`} style={gridSize}>
        <div className="TkTopSearch__select-container">
            <select onChange={onChange}
                    value={selectValue}
                    ref={selectRef}
                    className="TkTopSearch__select"
                    title='Selecione a área'>
                <option value={-1}>&nbsp;&nbsp;&nbsp;&nbsp;Todos</option>
                {divisions && divisions.map((c) => <option key={c._id} value={c._id}>{c.name}</option>)}
            </select>
        </div>

        <div className="TkTopSearch__input-container">
            <input type='search'
                   ref={inputRef}
                   value={term}
                   onChange={onInputChange}
                   onBlur={onInputBlur}
                   onKeyDown={onInputKeyDown}
                   onKeyUp={onInputKeyUp}
                   onFocus={onInputFocus}
                   onPaste={onInputPaste}
                   placeholder="Pesquisar"
                   className={`TkTopSearch__input ${showOptions ? 'active' : ''}`}/>

            {showOptions && <ListItems items={items} selectedIndex={index}/>}
        </div>

        <button className='TkTopSearch__button' aria-label='Botão de Pesquisa' type='button' onClick={goToSearch} disabled={searching}>
            {searching ? <TkSvgIcon className='rotate-1-seg' icon='sync-solid'/> : <TkSvgIcon icon='search'/>}
        </button>
    </div>;
};

export default memo(TkTopSearch);
