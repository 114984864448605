import {Order} from "../../models/order";

export interface OrderReducer {
    order?: Order,
    orderResult?: {
        success?: boolean
        order?: Order
        message?: string
        createdAt?: Date
        updatedAt?: Date
    }
}

export enum OrderReducerActionType {
    make_order,
    make_order_error,
}

export interface OrderReducerAction {
    payload?: any,
    type: OrderReducerActionType
}

const TkOrderReducer = (state: OrderReducer, action: OrderReducerAction): OrderReducer => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case OrderReducerActionType.make_order:
            newState.order = action.payload.order;
            newState.orderResult = action.payload;
            return newState;
        case OrderReducerActionType.make_order_error:
            newState.orderResult = action.payload;
            newState.order = undefined;
            return newState;
        default:
            return state;
    }

};


export default TkOrderReducer
