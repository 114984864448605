import "slick-carousel/slick/slick.css";
import './style.scss'
import TkSliderCategoryCard from "./_carouselCategory";
import TkCarouselClass from "./_carouselClass";
import TkSliderManufacturerCard from "./_carouselManufacturer";
import TkCarouselRoot from "./_carouselRoot";
import Product from "./_carouselProduct";


const TkCarousel = {
    Root: TkCarouselRoot,
    Category: TkSliderCategoryCard,
    Class: TkCarouselClass,
    Manufacturer: TkSliderManufacturerCard,
    Product,
}

export default TkCarousel