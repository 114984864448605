const productSimpleDefault = '_id manufacturerName manufacturerId manufacturerReference price promoPrice stock isActive isWithdraw unitCommercialName name complement thumbnail stars leadTime';

export const cartBasedAssociationRecommenderQuery = `query ($_id: String!, $quantity: Int){
  cartBasedAssociationRecommender(_id: $_id, quantity: $quantity) {${productSimpleDefault}}
}`;

export const bestSellersRecommenderQuery = `query ($quantity: Int){
  bestSellersRecommender(quantity: $quantity) {${productSimpleDefault}}
}`;

export const userBasedRecommenderQuery = `query {
  userBasedRecommender {${productSimpleDefault}}
}`;

export const complementRecommenderQuery = `query ($quantity: Int){
  complementRecommender(quantity: $quantity) {${productSimpleDefault}}
}`;

export const registerEngagementMutation = `mutation ($screen: String!, $type: String!, $productIds: [String!]!) {
  registerEngagement(screen: $screen, type: $type, productIds: $productIds)
}`;
