import './style.scss'
import React, {memo} from "react";
import {ITkProductModel} from "../../../models/product";
import TkProportionalImage from "../../particles/TkProportionalImage";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import {formatMoney} from "../../../utils/number-utils";
import {Link} from "react-router-dom";
import {tk_route_manufacturer} from "../../../views/TkSearchView";
import LoadingPhoto from '../../../assets/images/tail-spin.svg'
import TkSkeleton from "../../particles/TkSkeleton";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-photo.svg';

const TkCardProductSlider: React.FC<{
    products: ITkProductModel[]
    isLoadingProducts?: boolean
}> = ({products, isLoadingProducts= false}) => {

    if (isLoadingProducts) return <div className='TkCardProductSlider__carousel'>
        {Array(4).fill(null).map((v, idx) => {
            return <div key={idx} className='TkCardProductSlider__card'>
                <TkProportionalImage url={LoadingPhoto} alt='Carregando...' plainB={NoPhoto}/>
                <h1 className='TkCardProductSlider__title'><TkSkeleton width={90}/></h1>
                <span className="TkCardProduct__brand">
                    <TkSkeleton width={60}/>
                </span>
                <div className='TkCardProductSlider__price-container'>
                    <span className='TkCardProductSlider__price'>
                        <TkSkeleton width={60}/>
                    </span>
                </div>
            </div>
        })}
    </div>
    return <div className='TkCardProductSlider__carousel'>
        {products?.map((p, idx) => {
            const {name, manufacturerName, manufacturerId, promoPrice, thumbnail, stock, minimumSaleQuantity, controlMultiplicity, leadTime} = p;

            const inStock = stock && stock > 0 && (!controlMultiplicity || (controlMultiplicity && stock >= minimumSaleQuantity));

            return <article key={idx} className='TkCardProductSlider__card'>
                <Link to={tk_route_product_detail(p)} key={idx}>
                    <TkProportionalImage url={!!thumbnail ? thumbnail : NoPhoto} alt={name} plainB={NoPhoto}/>
                    <h1 className='TkCardProductSlider__title'>{name}</h1>
                </Link>
                <span className="TkCardProduct__brand">
                    por&nbsp;
                    <Link
                    to={tk_route_manufacturer(manufacturerId)}
                    onClick={(e) => e.stopPropagation()}
                    >
                    {manufacturerName}
                    </Link>
                </span>
                <div className='TkCardProductSlider__price-container'>
                    <span className='TkCardProductSlider__price'>{formatMoney(promoPrice)}</span>
                </div>
                {inStock ? <div className="TkCardProductSlider__available">Em estoque</div> : leadTime && leadTime > 0 ? <span className="TkCardProductSlider__backorder">Sob Encomenda</span> : <div className="TkCardProductSlider__out-of-stock">Indisponível</div>}

            </article>
        })}
    </div>
};

export default memo(TkCardProductSlider)
