import React from "react";
import './style.scss';
import TkSvgIcon from "../TkSvgIcon";

const TkChip: React.FC<{
    label: string
    icon?: string
    remove(): void
}> = ({label, icon, remove}) => {
    return <span className='TkChip__chip'>
        {icon && <TkSvgIcon icon={icon} className='TkChip__icon'/>}
        <span className='TkChip__label ellipsis' title={label}>{label}</span>
        <span className='TkChip__delete' onClick={remove} title='Clique para remover'>&times;</span>
    </span>
};

export default TkChip
