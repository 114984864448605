import React, {CSSProperties, useCallback, useEffect, useRef, useState} from "react";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {useTkProduct, useTkWhatsAppBalloon} from "../../context/TkContext";
import TkHorizontalCardProduct from "../../components/product/TkHorizontalCardProduct";
import {Link, useHistory, useLocation} from "react-router-dom";
import {ITkProductModel} from "../../models/product";
import {isBlank} from "../../utils/string-utils";
import TkPaginator from "../../components/particles/TkPaginator";
import {smoothScroll} from "../../utils/utils";
import TkChip from "../../components/particles/TkChip";
import TkHasNoResults from "./_hasNoResults";
import {ClassifiersType} from "../../context/TkProductContext";
import AutocompleteClassifier, {ClassifierType} from "./_autocompleteClassifiers";
import {useClassifierFilter} from "./_classifiersFilterStore";
import {BooleanQuerySearch} from "./BooleanQuerySearch";


const Filters: React.FC<{
  total?: number;
  searching?: boolean;
  hasNoResults?: boolean;
}> = ({total, searching = false, hasNoResults = false}) => {
  const location = useLocation();
  const history = useHistory();
  const {setShowIcon} = useTkWhatsAppBalloon()
  const {loadClassifiers, state: {divisions}} = useTkProduct();
  const [isShow, setIsShow] = useState(false);
  const [isShowOrdering, setIsShowOrdering] = useState(false);
  const [style, setStyle] = useState<CSSProperties>({});
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [priceStartValue, setPriceStartValue] = useState("");
  const [priceEndValue, setPriceEndValue] = useState("");
  const [showAvailableStock, setShowAvailableStock] = useState(false);
  const [showPromotions, setShowPromotions] = useState(false);
  const {selectedFamiliesIds, selectedClassesIds} = useClassifierFilter()

  useEffect(() => {
    const param = new URLSearchParams(location.search);

    setShowPromotions(param.get("showPromotions") === 'true')
    setShowAvailableStock(param.get("showAvailableStock") === 'true')

    const priceStart = param.get("priceStart")
    const priceEnd = param.get("priceEnd")

    setPriceStartValue(priceStart || '')
    setPriceEndValue(priceEnd || '')

  }, [location.search, setShowPromotions, setShowAvailableStock, setPriceStartValue, setPriceEndValue])

  const changePromoAndStock = useCallback((field: BooleanQuerySearch) => {
    const param = new URLSearchParams(location.search);

    if (field === BooleanQuerySearch.PROMO) param.set("showPromotions", (param.get("showPromotions") !== 'true') + '');
    else if (field === BooleanQuerySearch.STOCK) param.set("showAvailableStock", (param.get("showAvailableStock") !== 'true') + '');

    history.push(`${location.pathname}?${param.toString()}`);
  }, [location])

  const linksPrice = [{
    to: urlPrice(0, 100),
    label: "Até R$ 100,00",
  }, {
    to: urlPrice(100, 300),
    label: "R$ 100,00 a R$ 300,00",
  }, {
    to: urlPrice(300, 1000),
    label: "R$ 300,00 a R$ 1.000,00",
  }, {
    to: urlPrice(1000, 0),
    label: "Acima de R$ 1.000,00",
  }];

  const defaultFormState = {
    divisionId: "",
    familyIds: "",
    classIds: "",
    groupIds: "",
    manufacturerIds: "",
  };
  const [formState, setFormState] = useState({...defaultFormState});

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    loadClassifiers(ClassifiersType.DIVISIONS, ClassifiersType.MANUFACTURERS, ClassifiersType.FAMILIES, ClassifiersType.CLASSES, ClassifiersType.GROUPS)

    const params = new URLSearchParams(location.search);

    if (params.has('showPromotions') && params.get('showPromotions') == "true") {
      setShowPromotions(true)
    }

    if (params.has('showAvailableStock') && params.get('showAvailableStock') == "true") {
      setShowAvailableStock(true)
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newState: any = {...formState};

    Object.keys(formState).forEach((k) => {
      if (params.has(k)) newState[k] = params.get(k);
    });

    if (params.has("divisionId")) {
      const divisionId = params.get("divisionId");
      const result = divisions
        ? divisions.filter((d) => d._id === divisionId)
        : [];

      if (result.length > 0) setSelectedDivision(result[0]);
    } else {
      setSelectedDivision(null);
    }

    setFormState(newState);
  }, [location, divisions, setSelectedDivision, setFormState]);

  const toggle = useCallback((is: boolean) => {
      if (is) window.scrollTo({ top: 0});

      const dim = ref.current.getBoundingClientRect();
      const calc = window.innerHeight - (dim.y + dim.height);
      document.body.classList[is ? "add" : "remove"]("o-h");

      setStyle({
        height: is ? `${calc}px` : 0,
        opacity: is ? 1 : 0,
        zIndex: is ? 2 : "auto",
      });

      setIsShow(is);
      setShowIcon(!is)
    },
    [ref, setIsShow, setStyle, setShowIcon]
  );

  const toggleOrdering = useCallback(() => {
    const is = !isShowOrdering;
    document.body.classList[is ? "add" : "remove"]("o-h");
    setIsShowOrdering(is);
  }, [isShowOrdering, setIsShowOrdering]);

  const changeOrdering = useCallback((ordering: string) => {
      const params = new URLSearchParams(location.search);
      params.delete("priceOrder");
      params.delete("nameOrder");

      if (/price/gi.test(ordering))
        params.set("priceOrder", /Asc$/gi.test(ordering) + "");
      else if (/name/gi.test(ordering)) params.set("nameOrder", "true");

      setIsShowOrdering(false);
      toggle(false);

      const timer = setTimeout(() => {
        history.push(`${location.pathname}?${params.toString()}`);
      }, 300);

      return () => clearTimeout(timer);
    },
    [location, setIsShowOrdering, toggle]
  );

  const clearFilters = useCallback(() => {
    setFormState({...defaultFormState});

    toggle(false);
    const timer = setTimeout(() => {
      history.push(location.pathname);
    }, 310);

    return () => clearTimeout(timer);
  }, [setFormState]);

  function removeDivision() {
    const params = new URLSearchParams(location.search);
    params.delete("divisionId");
    params.delete("divisionName");

    toggle(false);
    setTimeout(() => {
      history.push(`${location.pathname}?${params.toString()}`);
    }, 310);
  }

  function urlPrice(start: number, finish = 0) {
    const params = new URLSearchParams(location.search);
    params.set("priceStart", `${start}`);
    params.delete("priceEnd");
    if (finish > 0) params.set("priceEnd", `${finish}`);
    return params;
  }

  function filterByPrice() {
    const params = new URLSearchParams(location.search);
    params.delete("priceStart");
    params.delete("priceEnd");

    if (!isBlank(priceStartValue)) params.set("priceStart", priceStartValue);
    if (!isBlank(priceEndValue)) params.set("priceEnd", priceEndValue);

    toggle(false);
    setTimeout(() => {
      history.push(`${location.pathname}?${params.toString()}`);
    }, 310);
  }

  const message = !hasNoResults ? `${total} resultado` + (total > 1 ? `s` : "") : "nenhum resultado";

  return <div style={{position: "relative"}}>
    <div ref={ref} className="TkSearchView__top-filter">
        <span className="TkSearchView__results">
          {searching ? (
            <TkSvgIcon className="rotate-1-seg" icon="sync-solid"/>
          ) : (
            message
          )}
        </span>
      <div style={{position: "relative"}}>
        <button
          type="button"
          onClick={toggleOrdering}
          className="btn btn-ice-gray btn-sm"
        >
          <TkSvgIcon icon="sort-amount-up-solid"/>
        </button>
        <ul
          className={`TkSearchView__ordering ${
            isShowOrdering ? "TkSearchView__ordering--active" : ""
          }`}
        >
          <li onClick={() => changeOrdering("")}>Destaques</li>
          <li onClick={() => changeOrdering("priceDesc")}>
            Ordernar por maior preço
          </li>
          <li onClick={() => changeOrdering("priceAsc")}>
            Ordernar por menor preço
          </li>
          <li onClick={() => changeOrdering("nameAsc")}>Ordernar por nome</li>
        </ul>
        <button
          type="button"
          onClick={() => toggle(!isShow)}
          className="btn btn-ice-gray btn-sm m-l-1em"
        >
          Filtros&nbsp;
          <TkSvgIcon
            icon={`caret-${isShow ? "down" : "up"}-solid`}
            className="m-l-1em"
          />
        </button>
      </div>
    </div>
    <div className="TkSearchView__filter-container" style={style}>
      <div key={isShow + ""} style={{overflowY: "auto", padding: 10}}>
        {selectedDivision && (
          <>
            <h2 className="TkSearchView__filter-title">Mostrando apenas</h2>
            <TkChip
              label={selectedDivision.name}
              remove={removeDivision}
              icon={selectedDivision.icon}
            />
            <hr/>
          </>
        )}

        <div className="form-group m-t-10px">
          <div>
            <label>
              <input
                type="checkbox"
                onChange={() => changePromoAndStock(BooleanQuerySearch.STOCK)}
                name="showAvailableStock"
                checked={showAvailableStock}
                style={{transform: "scale(1.3)", marginRight: "5px"}}
              />&nbsp;
              Somente produtos com estoque disponível
            </label>
          </div>
        </div>
        <div className="form-group m-t-10px">
          <div>
            <label>
              <input
                type="checkbox"
                onChange={() => changePromoAndStock(BooleanQuerySearch.PROMO)}
                name="showPromotions"
                checked={showPromotions}
                style={{transform: "scale(1.3)", marginRight: "5px"}}
              />&nbsp;
              Produtos em Promoção
            </label>
          </div>
        </div>
        <hr/>
        <AutocompleteClassifier type={ClassifierType.MANUFACTURER}/>
        <AutocompleteClassifier type={ClassifierType.FAMILY}/>
        <AutocompleteClassifier type={ClassifierType.CLASS} isVisible={selectedFamiliesIds.length > 0}/>
        <AutocompleteClassifier type={ClassifierType.GROUP} isVisible={selectedClassesIds.length > 0}/>

        <div className="form-group m-t-10px">
          <label>
            <b>Preço</b>
          </label>
          {linksPrice &&
            linksPrice.map((l, idx) => {
              const currentParams = new URLSearchParams(location.search);
              const params = new URLSearchParams(l.to);
              const start = currentParams.get("priceStart");
              const end = currentParams.get("priceEnd");
              let active =
                start === params.get("priceStart") &&
                end === params.get("priceEnd");

              return <div key={idx}>
                <Link
                  to={`${location.pathname}?${l.to.toString()}`}
                  onClick={() => toggle(false)}
                  className="TkSearchView__link-price"
                >
                  {active ? <TkSvgIcon icon="check-solid"/> : ""}
                  {l.label}
                </Link>
              </div>
            })}

          <div className="TkSearchView__price-container m-t-15px">
            <input
              type="number"
              min={1}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) e.preventDefault();
              }}
              onChange={(e) => setPriceStartValue(e.target.value)}
              value={priceStartValue}
              className="form-control form-control-sm"
              placeholder="R$ De"
            />
            <input
              type="number"
              min={1}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) e.preventDefault();
              }}
              onChange={(e) => setPriceEndValue(e.target.value)}
              value={priceEndValue}
              className="form-control form-control-sm"
              placeholder="R$ Até"
            />
            <button
              type="button"
              onClick={filterByPrice}
              className="btn btn-sm btn-ice-gray"
            >
              Ir
            </button>
          </div>
        </div>
      </div>

      <div className="TkSearchView__filter-actions t-a-r">
        <button
          type="button"
          onClick={clearFilters}
          className="btn btn-ice-gray btn-sm m-l-1em"
        >
          Limpar filtros
        </button>
        <button
          type="button"
          onClick={() => toggle(!isShow)}
          className="btn btn-ice-gray btn-sm m-r-1em"
        >
          &times; Fechar
        </button>
      </div>
    </div>
  </div>
};

const SmallScreen: React.FC<{
  loading: boolean;
  hasNoResults: boolean;
  total: number;
  products: ITkProductModel[];
}> = ({loading = false, hasNoResults = false, total = 0, products = []}) => {
  const history = useHistory();

  const goToUrl = useCallback((url: string) => {
    smoothScroll(0, 500, () => history.push(url));
  }, []);

  return <>
    <Filters total={total} searching={loading} hasNoResults={hasNoResults}/>

    <div>
      {hasNoResults && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <TkHasNoResults/>
            </div>
          </div>
        </div>
      )}
      {products &&
        products.map((p, idx) => (
          <TkHorizontalCardProduct
            key={idx}
            product={p}
            showSkeleton={loading}
          />
        ))}
    </div>
    {!hasNoResults && <TkPaginator goToUrl={goToUrl} totalItems={total}/>}
  </>
    ;
};

export default SmallScreen;
