import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import {useTkMedia, useTkProduct} from "../../../context/TkContext";
import {isIntersectionObserver} from "../../../utils/utils";
import TkCarousel from "../TkCarousel";
import {ProductListResult} from "../../../context/TkProductContext";
import TkCardProductSlider from "../TkCardProductSlider";

const TkCarouselOtherProducts:FC = () => {
    const {isMediaXs, isMediaSm} = useTkMedia()
    const {getProductsOutdoor} = useTkProduct();
    const refRelated = useRef<HTMLSpanElement>()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [productHighLights, setProductHighLights] =
        useState<ProductListResult>();
    const [productPromotions, setProductPromotions] =
        useState<ProductListResult>();

    useEffect(() => {
        let observer: any;

        if (refRelated.current && isIntersectionObserver()) {
            observer = new IntersectionObserver((entries) => {
                    entries.forEach(async (entry) => {
                        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                            observer.unobserve(refRelated.current)
                            setLoading(true)
                            Promise.all([
                                getProductsOutdoor("__vitrine__", 40),
                            ])
                                .then(([vitrine]) => {
                                    setProductHighLights(vitrine)
                                })
                                .finally(() => setLoading(false))
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "100px",
                }
            );
            observer.observe(refRelated.current);
        }

        return () => {
            if (observer?.unobserve && refRelated.current) observer.unobserve(refRelated.current);
        };
    }, [refRelated, setLoading]);

    const productsToShow = useMemo(() => {
        let result:any[] = []
        if (!!productHighLights && productHighLights.items?.length > 0) {
            result = productHighLights?.items
        }
        return result
    }, [productHighLights, productPromotions])

    return <>
        <span ref={refRelated}/>
        {isMediaXs || isMediaSm ?
            <>
                <h1 className='carousel-title'>Outros destaques</h1>
                <TkCardProductSlider products={productsToShow} isLoadingProducts={isLoading}/>
            </>
            :
            <TkCarousel.Root title='Outros destaques'>
                <div className='TkCarousel__padding'>
                    <TkCarousel.Product products={productsToShow} isShowSkeleton={isLoading}/>
                </div>
            </TkCarousel.Root>
        }

    </>
}

export default TkCarouselOtherProducts