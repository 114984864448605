import React, {memo} from "react";
import {ITkProductModel} from "../../models/product";
import {Breadcrumb} from "../../context/TkBreadCrumbContext";
import {tk_route_class, tk_route_division, tk_route_family} from "../TkSearchView";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";

const ProductBreadCrumb: React.FC<{
    product: ITkProductModel | null | undefined
}> = ({product}) => {

    if (!product?.hierarchies || product?.hierarchies?.length === 0) return <TkBreadcrumb showSkeleton={true} list={null}/> ;

    const {division, family, class: clazz} = product.hierarchies[0]


    const list: Breadcrumb[] = [{
        url: tk_route_division(division),
        label: division.name || ''
    }, {
        url: tk_route_family(family),
        label: family.name
    }, {
        url: tk_route_class(clazz),
        label: clazz.name
    }];

    return <TkBreadcrumb list={list}/>;
};

export default memo(ProductBreadCrumb)
